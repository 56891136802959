import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import format from "date-fns/format";
import dayjs from "dayjs";
import Divider from "@mui/material/Divider";
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
} from "@mui/material";
import NavLinkAdapter from "../../components/NavLinkAdapter";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import Pagination from "../../components/Pagination";
import { backend_url } from "../../components/Constants";

function PSApprovalHistory(props) {
  const { token } = props;
  const [trips, setTrips] = useState([]);
  const [history, setHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = history.count;
  const links = history.links;
  // const itemsPerPage = trips && trips.results ? trips.results.trip_data.length : []
  const trip_data = trips && trips.results ? trips.results : [];
  //   const totalPages = Math.ceil(totalItems / 50);
  //   const handlePageChange = (pageNumber) => {
  //     setCurrentPage(pageNumber);
  //   };

  const history_data = history && history.results ? history.results : [];
  const totalPages = Math.ceil(totalItems / 50);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const todayAtMid = dayjs().set("hour", 0).startOf("hour");
  const tripDate = new Date(trip_data.length > 0 && trip_data[0].program_to);
  const todayDate = new Date(todayAtMid);
  const Difference_In_Time = todayDate.getTime() - tripDate.getTime();
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/traveller/details/?` +
          new URLSearchParams({
            trc_p: currentPage,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setTrips(data));
    }

    fetchData();
  }, [token, currentPage]);

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/traveller/approvals_history/?` +
          new URLSearchParams({
            trc_p: currentPage,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setHistory(data));
    }

    fetchData();
  }, [token, currentPage]);

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
      className="flex flex-col flex-auto w-full max-h-full m-8"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="sm:px-12 py-1 sm:py-3 sm:my-3">
          {history && history_data.length > 0 && (
            <div>
              <motion.div
                variants={item}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                className="flex flex-col flex-auto w-full max-h-full m-0 sm:m-8"
              >
                <List className="w-full m-0 p-0" disablePadding>
                  <ListItem className="sm:px-12 py-3 my-3">
                    <ListItemText
                      classes={{
                        root: "m-0",
                        primary: "font-medium leading-5 truncate",
                      }}
                      primary={
                        <Typography component="h5" variant="h5">
                          {history.count} Approved Applications
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
                <Divider />
              </motion.div>


              <motion.div variants={item}>
                {history_data.map((trip_details) => {
                  return (
                    (trip_details.applicant_designation === "CHAIRPERSON" ||
                      trip_details.applicant_designation === "CEO" ||
                      trip_details.applicant_designation === "PS" ) && (
                      <List
                        key={trip_details.trip_id}
                        disablePadding
                        sx={{ bgcolor: "background.paper", px: 1, my: 2 }}
                      >
                        <ListItemButton
                          className="px-0 sm:-mx-8"
                          disableGutters
                          component={NavLinkAdapter}
                        >
                          <IconButton sx={{ color: "secondary.dark" }}>
                            <ConnectingAirportsIcon fontSize="inherit" />
                          </IconButton>
                          <ListItemText
                            className="px-2"
                            primary={
                              <div>
                                <div className="sm:flex sm:flex-row items-center space-x-12">
                                  {/* <Typography
                                    component="h6"
                                    variant="h6"
                                    className="sm:flex sm:flex-auto sm:items-left sm:text-left"
                                  >
                                    {trip_details.trip_type === "public"
                                      ? trip_details.program_title
                                      : `${trip_details.reason_for_tr} - Private Travel`}
                                  </Typography> */}
                                  <div
                                    className="sm:flex sm:flex-auto sm:items-left sm:text-left font-bold"
                                    dangerouslySetInnerHTML={{
                                      __html: trip_details.reason_for_tr,
                                    }}
                                  />
                                  <Typography
                                    className="flex py-6 sm:py-0 sm:flex-auto text-left sm:text-right text-8"
                                    variant="p"
                                  >
                                    {trip_details.program_from !== undefined &&
                                      format(
                                        new Date(trip_details.program_from),
                                        "dd-MMM-yyyy"
                                      )}
                                  </Typography>
                                </div>
                                <Typography variant="p">
                                  {trip_details.applicant_first_name}{" "}
                                  {trip_details.applicant_last_name} -{" "}
                                  {trip_details.applicant_designation}{" "}
                                </Typography>
                              </div>
                            }
                            secondary={
                              <Typography variant="p">
                                {trip_details.country}
                              </Typography>
                            }
                          />
                        </ListItemButton>

                        <div className="flex mb-4 px-24">
                          <Typography
                            variant="p"
                            className="text-8 font-medium"
                            color="secondary"
                          >
                            Status:{" "}
                            <span className="text-grey-900">
                              {trip_details.ps_confirm === "accepted"
                                ? "Accepted"
                                : trip_details.ps_confirm ===
                                  "return_application"
                                ? "Returned application"
                                : "Not Concurred"}{" "}
                            </span>
                          </Typography>
                        </div>
                        <Typography
                          variant="p"
                          className="text-8 font-medium mb-4 px-24"
                          color="secondary"
                        >
                          Comment:{" "}
                          <span className="text-grey-900">
                            {trip_details.ps_reasons}
                          </span>
                        </Typography>

                        <Divider />
                      </List>
                    )
                  );
                })}
              </motion.div>
            </div>
          )}

          {history_data && history_data.length > 0 && (
            <div className="my-16 mx-8">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                previousPage={links.previous}
                nextPage={links.next}
              />
            </div>
          )}
        </div>
      </Box>
    </motion.div>
  );
}

export default PSApprovalHistory;
