import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import _ from "../@lodash";
import axios from "axios";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
// import InputAdornment from "@mui/material/InputAdornment";
import {
  Autocomplete,
  Container,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import logo from "../assets/logo1.png";
import Loading from "../components/Loading";
import TravelSnackbar from "../components/TravelSnackbar";
import kenya_flag from "../assets/kenya_flag.png";
import { backend_url } from "../components/Constants";
import { min_state_inst } from "../components/institutions";

function SignUp() {
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [chair, setChair] = useState("");
  const [diplomat, setDiplomat] = useState(false);
  const [isCS, setIsCS] = useState(false);
  const [isPS, setIsPS] = useState(false);
  const [designation, setDesignation] = useState(false);

  const [selectedMinistry, setSelectedMinistry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [filteredMinistry, setFilteredMinistry] = useState([]);
  const [filteredState, setFilteredState] = useState([]);

  const handleMinistryChange = (newValue) => {
    const selectedMinistry = newValue;

    // Filter the data based on the selected Ministry
    const filteredMinistries = min_state_inst.filter(
      (item) => item.Ministry === selectedMinistry
    );

    setFilteredMinistry(filteredMinistries);
    setSelectedMinistry(selectedMinistry);
  };

  const handleStateChange = (newValue) => {
    const selectedState = newValue;

    // Filter the data based on the selected Ministry
    const filteredStates =
      selectedState === "None"
        ? min_state_inst.filter((item) => item.Ministry === selectedMinistry)
        : min_state_inst.filter(
            (item) => item.State_Departments === selectedState
          );

    setFilteredState(filteredStates);
    setSelectedState(selectedState);
  };

  const ministries = _.uniqWith(
    min_state_inst.map((min) => min.Ministry),
    _.isEqual
  );

  const department =
    filteredMinistry &&
    // choices.state_departments !== undefined &&
    _.uniqWith(
      filteredMinistry.map((department) => department.State_Departments),
      _.isEqual
    );

  const institution =
    filteredState &&
    // choices.institution !== undefined &&
    _.uniqWith(
      filteredState.map((institution) => institution.Institutions),
      _.isEqual
    );

  const schema = yup.object().shape({
    // username: yup.string().required("You must enter user name"),
    email: yup
      .string()
      .email("Your email should end with .ke")
      .required("You must enter a valid email"),
    // password: yup
    //   .string()
    //   .required("Please enter your password.")
    //   .min(8, "Password is too short - should be 8 chars minimum."),
    first_name: yup.string().required("Please enter your first name."),
    last_name: yup.string().required("Please enter your last name."),
    salutation: yup
      .string()
      .required("Please enter your salutation. E.g Dr, Hon"),
    telephone_no: yup
      .number()
      .required("You must enter your telephone number")
      .min(4, "Number is too short - should be in this format (712345678)")
      .nullable(true),
    id_number: yup
      .number()
      .required("You must enter your ID number")
      .min(3, "Number is too short - should be 3 chars minimum")
      .nullable(true),
    personal_no:
      chair === true
        ? yup.string().notRequired()
        : yup
            .string()
            .required("You must enter your personal number")
            .min(2, "Number is too short - should be 2 chars minimum")
            .nullable(true),
    passport_no:
      diplomat === true
        ? yup.string().notRequired()
        : yup
            .string()
            .required("You must enter your passport number")
            .min(4, "Too short - should be 4 chars minimum")
            .nullable(true),
    diplomatic_passport_no:
      diplomat === true
        ? yup
            .string()
            .required("You must enter your passport number")
            .min(4, "Too short - should be 4 chars minimum")
            .nullable(true)
        : yup.string().notRequired(),
    gender: yup
      .string()
      .required()
      .oneOf(["F", "M", "B"], "Please select the gender"),
    designation: yup
      .string()
      .required()
      .oneOf(
        [
          "CS",
          "PS",
          "CEO",
          "CHAIRPERSON",
          "HOPS_CHECKING",
          "HOPS_CONFIRMATION",
          "MIN_FOREIGN",
          "HOPS_APPROVAL",
          "HOPS_PA",
          "New",
        ],
        "Please select the designation"
      ),
    ministry: yup
      .string()
      .required("You must enter your ministry")
      .oneOf(
        ministries !== false ? ministries : [],
        "Please select the ministry"
      ),
    State_departments:
      isCS === true
        ? yup.string().notRequired()
        : yup
            .string()
            .required("You must enter your state department")
            .oneOf(
              department !== false ? department : [],
              "Please select the state department"
            ),
    institutions:
      isCS === true || isPS === true
        ? yup.string().notRequired()
        : yup
            .string()
            .required("You must enter your institution")
            .oneOf(
              institution !== false ? institution : [],
              "Please select the institution"
            ),
    job_group: yup
      .string()
      .notRequired()
      .oneOf(
        [
          "F4",
          "U",
          "V",
          "E5",
          "F1",
          "F2",
          "E3",
          "E4",
          "S",
          "T",
          "D5",
          "E1",
          "E2",
          "P",
          "Q",
          "R",
          "D1",
          "D2",
          "D3",
          "K",
          "L",
          "M",
          "N",
          "F",
          "G",
          "H",
          "I",
          "J",
          "A",
          "B",
          "C",
          "D",
          "E",
          "New",
        ],
        "Please select the job group"
      ),
    acceptTermsConditions: yup
      .boolean()
      .oneOf([true], "The terms and conditions must be accepted."),
  });

  const defaultValues = {
    // username: "",
    email: "",
    // password: "",
    first_name: "",
    last_name: "",
    salutation: "",
    id_number: "",
    // re_password: "",
    telephone_no: "",
    personal_no: "",
    gender: "",
    passport_no: "",
    diplomatic_passport_no: null,
    designation: "",
    ministry: [],
    job_group: "",
    State_departments: "None",
    institutions: "None",
    acceptTermsConditions: false,
  };

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    // isValid, dirtyFields,
    errors,
  } = formState;

  function submit({
    first_name,
    last_name,
    salutation,
    email,
    passport_no,
    gender,
    diplomatic_passport_no,
    // password,
    // re_password,
    id_number,
    telephone_no,
    personal_no,
    designation,
    ministry,
    State_departments,
    institutions,
    job_group,
  }) {
    const user = {
      first_name,
      last_name,
      salutation,
      email,
      gender,
      diplomatic_passport_no,
      passport_no,
      // password,
      // re_password,
      id_number,
      telephone_no,
      personal_no,
      designation,
      State_departments:
        State_departments === "None" ? null : State_departments,
      institutions: institutions === "None" ? null : institutions,
      ministry: ministry === "None" ? null : ministry,
      job_group,
      // job_group:
      //   designation === "CEO" || designation === "PS"
      //     ? "U"
      //     : designation === "CS"
      //     ? "V"
      //     : "U",
    };
    axios
      .post(
        `${backend_url}/auth/user/register/`,
        user,
        {
          headers: { "Content-Type": "application/json" },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Signed Up Successfully!");
        setLoading(false);
        localStorage.clear();
        window.location.href = "/confirmation";
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(
          err.response.data?.details ===
            "{'email': [ErrorDetail(string='Invalid email format. PLease use your official goverment email', code='invalid_email_format')]}"
            ? "Please use your official goverment email address (.ke)"
            : err.response.data?.details
            ? err.response.data?.details
            : err.response.data?.error
        );
        setLoading(false);
      });
  }

  return (
    <Container component="main" maxWidth="xl">
      <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-32 mt-56">
        <Paper className="flex w-full sm:w-auto min-h-full sm:min-h-auto md:w-full md:max-w-6xl rounded-0 sm:rounded-2xl sm:shadow overflow-hidden">
          <div className="w-full sm:w-auto py-32 px-16 sm:p-48 md:p-64 ltr:border-r-1 rtl:border-l-1">
            <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
              <img className="w-full sm:w-70" src={logo} alt="logo" />

              <br />

              {openMessage === true && (
                <TravelSnackbar
                  openMessage={openMessage}
                  severity={severity}
                  message={message}
                />
              )}

              <Typography
                variant="p"
                component="p"
                className="flex flex-col pt-16 sm:mt-32 text-xl sm:text-2xl font-bold sm:font-extrabold tracking-tight leading-tight"
              >
                Sign Up
              </Typography>

              <div className="sm:flex items-baseline pt-6 text-xs sm:text-sm font-small sm:font-medium">
                <Typography variant="p">Already have an account?</Typography>
                <Link className="ml-4 font-semibold" to="/sign-in">
                  Sign in
                </Link>
              </div>

              <form
                name="registerForm"
                noValidate
                className="flex flex-col justify-center w-full mt-16 sm:mt-32"
                onSubmit={handleSubmit(submit)}
              >
                <Controller
                  name="first_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ mb: 2 }}
                      label="First name"
                      error={!!errors.first_name}
                      helperText={errors?.first_name?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />

                <Controller
                  name="last_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ mb: 2 }}
                      label="Last name"
                      error={!!errors.last_name}
                      helperText={errors?.last_name?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />

                <Controller
                  name="salutation"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ mb: 2 }}
                      label="Salutation e.g Dr, Hon."
                      error={!!errors.salutation}
                      helperText={errors?.salutation?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />

                <Controller
                  name="designation"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      sx={{ mb: 2 }}
                      fullWidth
                      error={!!errors.designation}
                    >
                      <InputLabel id="designation1">Designation</InputLabel>
                      <Select
                        labelId="designation1"
                        id="designation"
                        label="Designation"
                        {...field}
                      >
                        <MenuItem
                          value={"CHAIRPERSON"}
                          onClick={() => {
                            setChair(true);
                            setDiplomat(false);
                            setIsPS(false);
                            setIsCS(false);
                            setDesignation(true);
                          }}
                        >
                          Chairperson
                        </MenuItem>
                        <MenuItem
                          value={"CEO"}
                          onClick={() => {
                            setChair(false);
                            setDiplomat(false);
                            setIsPS(false);
                            setIsCS(false);
                            setDesignation(true);
                          }}
                        >
                          CEO
                        </MenuItem>
                        <MenuItem
                          value={"PS"}
                          onClick={() => {
                            setChair(false);
                            setDiplomat(true);
                            setIsPS(true);
                            setIsCS(false);
                            setDesignation(true);
                          }}
                        >
                          PS
                        </MenuItem>
                        <MenuItem
                          value={"CS"}
                          onClick={() => {
                            setChair(false);
                            setDiplomat(true);
                            setIsPS(false);
                            setIsCS(true);
                            setDesignation(true);
                          }}
                        >
                          CS
                        </MenuItem>

                        {/* <MenuItem
                          value={"MIN_FOREIGN"}
                          onClick={() => {
                            setChair(false);
                            setDiplomat(false);
                            setIsPS(false);
                            setIsCS(false);
                            setDesignation(true);
                          }}
                        >
                          MFA
                        </MenuItem>
                        <MenuItem
                          value={"IMMIGRATION"}
                          onClick={() => {
                            setChair(false);
                            setDiplomat(false);
                            setIsPS(false);
                            setIsCS(false);
                            setDesignation(true);
                          }}
                        >
                          Immigration
                        </MenuItem> */}
                      </Select>
                      <FormHelperText>
                        {errors?.designation?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />

                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ mb: 2 }}
                      label="Email"
                      type="email"
                      error={!!errors.email}
                      helperText={errors?.email?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />

                <Controller
                  name="id_number"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ mb: 2 }}
                      label="ID number"
                      type="number"
                      error={!!errors.id_number}
                      helperText={errors?.id_number?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />

                <Controller
                  name="telephone_no"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ mb: 2 }}
                      label="Telephone/ Phone number e.g (712345678)"
                      variant="outlined"
                      error={!!errors.telephone_no}
                      helperText={
                        <Typography variant="caption" color="error">
                          {" "}
                          {errors?.telephone_no?.message}{" "}
                        </Typography>
                      }
                      required
                      fullWidth
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box
                              component="span"
                              sx={{ "& > img": { mr: 0, flexShrink: 0 } }}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                src={kenya_flag}
                                alt="kenya flag"
                              />
                              <span className="ml-4 font-medium">+254</span>
                            </Box>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                <Controller
                  name="job_group"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      sx={{ mb: 2 }}
                      fullWidth
                      error={!!errors.job_group}
                    >
                      <InputLabel id="job_group1">Job group</InputLabel>
                      <Select
                        labelId="job_group1"
                        id="job_group"
                        label="Job group"
                        {...field}
                        // value={job_group}
                        // onChange={(e) => setJob_group(e.target.value)}
                      >
                        <MenuItem value={"F4"}>F4</MenuItem>
                        <MenuItem value={"U"}>U</MenuItem>
                        <MenuItem value={"V"}>V</MenuItem>
                        <MenuItem value={"E5"}>E5</MenuItem>
                        <MenuItem value={"F1"}>F1</MenuItem>
                        <MenuItem value={"F2"}>F2</MenuItem>
                        <MenuItem value={"E3"}>E3</MenuItem>
                        <MenuItem value={"E4"}>E4</MenuItem>
                        <MenuItem value={"S"}>S</MenuItem>
                        <MenuItem value={"T"}>T</MenuItem>
                        <MenuItem value={"D5"}>D5</MenuItem>
                        <MenuItem value={"E1"}>E1</MenuItem>
                        <MenuItem value={"E2"}>E2</MenuItem>
                        <MenuItem value={"P"}>P</MenuItem>
                        <MenuItem value={"Q"}>Q</MenuItem>
                        <MenuItem value={"R"}>R</MenuItem>
                        <MenuItem value={"D1"}>D1</MenuItem>
                        <MenuItem value={"D2"}>D2</MenuItem>
                        <MenuItem value={"D3"}>D3</MenuItem>
                        <MenuItem value={"K"}>K</MenuItem>
                        <MenuItem value={"L"}>L</MenuItem>
                        <MenuItem value={"M"}>M</MenuItem>
                        <MenuItem value={"N"}>N</MenuItem>
                        <MenuItem value={"F"}>F</MenuItem>
                        <MenuItem value={"G"}>G</MenuItem>
                        <MenuItem value={"H"}>H</MenuItem>
                        <MenuItem value={"I"}>I</MenuItem>
                        <MenuItem value={"J"}>J</MenuItem>
                        <MenuItem value={"A"}>A</MenuItem>
                        <MenuItem value={"B"}>B</MenuItem>
                        <MenuItem value={"C"}>C</MenuItem>
                        <MenuItem value={"D"}>D</MenuItem>
                        <MenuItem value={"E"}>E</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />

                {chair === false && (
                  <Controller
                    name="personal_no"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        sx={{ mb: 2 }}
                        label="Personal/Employment number"
                        type="text"
                        error={!!errors.personal_no}
                        helperText={errors?.personal_no?.message}
                        variant="outlined"
                        required
                        fullWidth
                      />
                    )}
                  />
                )}

                {diplomat === true ? (
                  <>
                    <Controller
                      name="diplomatic_passport_no"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          sx={{ mb: 2 }}
                          label="Diplomatic Passport number"
                          error={!!errors.diplomatic_passport_no}
                          helperText={errors?.diplomatic_passport_no?.message}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      )}
                    />

                    <Controller
                      name="passport_no"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          sx={{ mb: 2 }}
                          label="Passport number"
                          error={!!errors.passport_no}
                          helperText={errors?.passport_no?.message}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      )}
                    />
                  </>
                ) : (
                  <Controller
                    name="passport_no"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        sx={{ mb: 2 }}
                        label="Passport number"
                        error={!!errors.passport_no}
                        helperText={errors?.passport_no?.message}
                        variant="outlined"
                        required
                        fullWidth
                      />
                    )}
                  />
                )}

                {designation === true && min_state_inst && (
                  <Controller
                    name="ministry"
                    control={control}
                    defaultValue={[]}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        sx={{ mb: 2 }}
                        // disablePortal
                        id="ministry"
                        fullWidth
                        // options={ministries}
                        options={Array.from(
                          new Set(min_state_inst.map((item) => item.Ministry))
                        )
                          .map((ministry) => ministry)
                          .sort()}
                        value={value}
                        onChange={(event, newValue) => {
                          onChange(newValue);
                          handleMinistryChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            helperText={errors?.ministry?.message}
                            error={!!errors.ministry}
                            {...params}
                            label="Ministry"
                          />
                        )}
                      />
                    )}
                  />
                )}

                {isCS === false && selectedMinistry && (
                  <Controller
                    name="State_departments"
                    control={control}
                    defaultValue={[]}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        sx={{ mb: 2 }}
                        // disablePortal
                        id="State_departments"
                        fullWidth
                        options={department.sort()}
                        value={value}
                        onChange={(event, newValue) => {
                          onChange(newValue);
                          handleStateChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            helperText={errors?.State_departments?.message}
                            error={!!errors.State_departments}
                            {...params}
                            label="State Department"
                          />
                        )}
                      />
                    )}
                  />
                )}

                {isCS === false && isPS === false && selectedState && (
                  <Controller
                    name="institutions"
                    control={control}
                    defaultValue={[]}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        sx={{ mb: 2 }}
                        // disablePortal
                        id="institutions"
                        fullWidth
                        options={institution.sort()}
                        value={value}
                        onChange={(event, newValue) => {
                          onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            helperText={errors?.institutions?.message}
                            error={!!errors.institutions}
                            {...params}
                            label="Institution"
                          />
                        )}
                      />
                    )}
                  />
                )}

                {/* <Controller
                  name="job_group"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      sx={{ mb: 2 }}
                      fullWidth
                      error={!!errors.job_group}
                    >
                      <InputLabel id="job_group1">Job group</InputLabel>
                      <Select
                        labelId="job_group1"
                        id="job_group"
                        label="Job group"
                        {...field}
                      >
                        <MenuItem value={"F4"}>F4</MenuItem>
                        <MenuItem value={"U"}>U</MenuItem>
                        <MenuItem value={"V"}>V</MenuItem>
                        <MenuItem value={"E5"}>E5</MenuItem>
                        <MenuItem value={"F1"}>F1</MenuItem>
                        <MenuItem value={"F2"}>F2</MenuItem>
                        <MenuItem value={"E3"}>E3</MenuItem>
                        <MenuItem value={"E4"}>E4</MenuItem>
                        <MenuItem value={"S"}>S</MenuItem>
                        <MenuItem value={"T"}>T</MenuItem>
                        <MenuItem value={"D5"}>D5</MenuItem>
                        <MenuItem value={"E1"}>E1</MenuItem>
                        <MenuItem value={"E2"}>E2</MenuItem>
                        <MenuItem value={"P"}>P</MenuItem>
                        <MenuItem value={"Q"}>Q</MenuItem>
                        <MenuItem value={"R"}>R</MenuItem>
                        <MenuItem value={"D1"}>D1</MenuItem>
                        <MenuItem value={"D2"}>D2</MenuItem>
                        <MenuItem value={"D3"}>D3</MenuItem>
                        <MenuItem value={"K"}>K</MenuItem>
                        <MenuItem value={"L"}>L</MenuItem>
                        <MenuItem value={"M"}>M</MenuItem>
                        <MenuItem value={"N"}>N</MenuItem>
                        <MenuItem value={"F"}>F</MenuItem>
                        <MenuItem value={"G"}>G</MenuItem>
                        <MenuItem value={"H"}>H</MenuItem>
                        <MenuItem value={"I"}>I</MenuItem>
                        <MenuItem value={"J"}>J</MenuItem>
                        <MenuItem value={"A"}>A</MenuItem>
                        <MenuItem value={"B"}>B</MenuItem>
                        <MenuItem value={"C"}>C</MenuItem>
                        <MenuItem value={"D"}>D</MenuItem>
                        <MenuItem value={"E"}>E</MenuItem>
                        <MenuItem value={"New"}>New</MenuItem>
                      </Select>
                      <FormHelperText>
                        {errors?.job_group?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                /> */}

                {/* <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ mb: 2 }}
                      label="Username"
                      type="name"
                      error={!!errors.username}
                      helperText={errors?.username?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                /> */}

                <Controller
                  name="gender"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      sx={{ mb: 2 }}
                      fullWidth
                      error={!!errors.gender}
                    >
                      <InputLabel id="gender">Gender</InputLabel>
                      <Select
                        labelId="gender"
                        id="gender"
                        label="Gender"
                        {...field}
                      >
                        <MenuItem value={"M"}>Male</MenuItem>
                        <MenuItem value={"F"}>Female</MenuItem>
                        <MenuItem value={"B"}>Preferred not to say</MenuItem>
                      </Select>
                      <FormHelperText>{errors?.gender?.message}</FormHelperText>
                    </FormControl>
                  )}
                />

                {/* <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ mb: 2 }}
                      label="Password"
                      type="password"
                      error={!!errors.password}
                      helperText={errors?.password?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />

                <Controller
                  name="re_password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ mb: 2 }}
                      label="Password Confirm"
                      type="password"
                      error={!!errors.re_password}
                      helperText={errors?.re_password?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                /> */}

                <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between">
                  <Controller
                    name="acceptTermsConditions"
                    control={control}
                    render={({ field }) => (
                      <FormControl
                        className="items-center"
                        error={!!errors.acceptTermsConditions}
                      >
                        <FormControlLabel
                          label={
                            <Link
                              to="/terms-and-conditions"
                              underline="none"
                              target="_blank"
                            >
                              {" "}
                              I agree to the Terms of Service and Privacy Policy
                            </Link>
                          }
                          control={<Checkbox size="small" {...field} />}
                        />
                        <FormHelperText>
                          {errors?.acceptTermsConditions?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </div>

                <Button
                  variant="contained"
                  color="primary"
                  className=" w-full mt-16"
                  aria-label="Sign up"
                  // disabled={_.isEmpty(dirtyFields) || !isValid}
                  onClick={() => {
                    setOpenMessage(false);
                    setLoading(Object.keys(errors).length > 0 ? false : true);
                  }}
                  type="submit"
                  size="large"
                >
                  {loading === true ? (
                    Object.keys(errors).length > 0 ? (
                      setLoading(false)
                    ) : (
                      <Loading />
                    )
                  ) : (
                    "Create your account"
                  )}
                </Button>
              </form>
            </div>
          </div>

          <Box
            className="relative hidden lg:flex flex-auto items-center justify-center min-h-full p-64 lg:px-112 overflow-hidden"
            sx={{ backgroundColor: "primary.dark" }}
          >
            <svg
              className="absolute inset-0 pointer-events-none"
              viewBox="0 0 960 540"
              width="100%"
              height="100%"
              preserveAspectRatio="xMidYMax slice"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Box
                component="g"
                sx={{ color: "primary.light" }}
                className="opacity-20"
                fill="none"
                stroke="currentColor"
                strokeWidth="100"
              >
                <circle r="234" cx="196" cy="23" />
                <circle r="234" cx="790" cy="491" />
              </Box>
            </svg>
            <Box
              component="svg"
              className="absolute -top-64 -right-64 opacity-20"
              sx={{ color: "primary.light" }}
              viewBox="0 0 220 192"
              width="220px"
              height="192px"
              fill="none"
            >
              <defs>
                <pattern
                  id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect x="0" y="0" width="4" height="4" fill="currentColor" />
                </pattern>
              </defs>
              <rect
                width="220"
                height="192"
                fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
              />
            </Box>

            <div className="z-10 relative w-full max-w-2xl">
              <div className="text-7xl font-bold leading-none text-white text-center">
                <div>Travel</div>
                <div>Clearance</div>
                <div>System</div>
              </div>
              {/* <div className="mt-24 text-lg tracking-tight leading-6 text-white">
                                Chief of Staff and Head of Public Service
                            </div> */}
            </div>
          </Box>
        </Paper>
      </div>
    </Container>
  );
}

export default SignUp;
