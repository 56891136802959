import { Box, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { backend_url } from "../Constants";
import TotalCountWidget from "./widgets/TotalCountWidget";
import { motion } from "framer-motion";
import ApprovedTripsWidget from "./widgets/ApprovedTripsWidget";
// import AppealedTripsWidget from "./widgets/AppealedTripsWidget";
import TripTypeWidget from "./widgets/TripTypeWidget";
import SponsorWidget from "./widgets/SponsorWidget";
import ProgramTypeWidget from "./widgets/ProgramTypeWidget";
import FrequentHostWidget from "./widgets/FrequentHost";
import CostWidget from "./widgets/CostWidget";
import TicketCostWidget from "./widgets/TicketCostWidget";

export default function Reports({ token }) {
  const [reports, setReports] = useState();
  useEffect(() => {
    async function fetchData() {
      fetch(`${backend_url}/traveller/trip_reports`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => setReports(data));
    }

    fetchData();
  }, [token]);

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  return (
    reports !== undefined && (
      <div>
        <Box className="py-48 px-32">
          <Typography variant="h4" component="h3">
            Reports
          </Typography>
          <Divider />

          <motion.div
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-24 w-full min-w-0 pt-16"
            variants={container}
            initial="hidden"
            animate="show"
          >
            <TotalCountWidget totalCount={reports.total_count} token={token} />
            <ApprovedTripsWidget
              reviewer_stats={reports.reviewer_stats}
              token={token}
            />
            {/* <AppealedTripsWidget
              appealed_trips={reports.appealed_trips}
              token={token}
            /> */}
            <CostWidget cost_stats={reports.cost_stats} token={token} />
            <TicketCostWidget cost_stats={reports.cost_stats} token={token} />
          </motion.div>

          <motion.div
            className="w-full"
            variants={container}
            initial="hidden"
            animate="show"
          >
            <div className="grid grid-cols-1 xl:grid-cols-1 gap-24 w-full mt-24">
              <div className="grid gap-24 sm:grid-flow-col xl:grid-flow-row grid-cols-2">
                <motion.div variants={item} className="flex flex-col flex-auto">
                  <TripTypeWidget nature_stats={reports.nature_stats} />
                </motion.div>
                <motion.div variants={item} className="flex flex-col flex-auto">
                  <ProgramTypeWidget
                    program_type_stats={reports.program_type_stats}
                  />
                </motion.div>
              </div>
            </div>
            <div className="grid gap-24 sm:grid-flow-col xl:grid-flow-row grid-cols-2 py-32">
              <motion.div variants={item} className="flex flex-col flex-auto">
                <FrequentHostWidget frequent_hosts={reports.frequent_hosts} />
              </motion.div>
              <motion.div variants={item} className="flex flex-col flex-auto">
                <SponsorWidget sponsor_stats={reports.sponsor_stats} />
              </motion.div>
            </div>
          </motion.div>
        </Box>
      </div>
    )
  );
}
