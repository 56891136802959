import React, { useState, useEffect, useRef } from "react";
import { EditorState, Modifier, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Box, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

// Convert Draft.js editor state to HTML
const convertEditorStateToHTML = (editorState) => {
  const contentState = editorState.getCurrentContent();
  return draftToHtml(convertToRaw(contentState));
};

// Convert HTML to Draft.js editor state
const convertHTMLToEditorState = (html) => {
  const blocksFromHtml = htmlToDraft(html);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  return EditorState.createWithContent(contentState);
};

// Reusable Draft WYSIWYG Editor component
const DraftWysiwygEditor = ({
  control,
  name,
  label,
  errors,
  defaultValue = "",
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const max = 256; // number of characters allowed
  const editorRef = useRef(null);

  // CONFIG
  const MAX_LENGTH = max;

  // DATA & FUNCTIONS
  const currentCount = editorState.getCurrentContent().getPlainText().length;

  const _getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  const __handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = _getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
      console.log("You can type max 256 characters");

      return "handled";
    }
    return "not-handled";
  };

  const _removeSelection = () => {
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const endKey = selection.getEndKey();
    const endOffset = selection.getEndOffset();
    if (startKey !== endKey || startOffset !== endOffset) {
      const newContent = Modifier.removeRange(
        editorState.getCurrentContent(),
        selection,
        "forward"
      );
      const tempEditorState = EditorState.push(
        editorState,
        newContent,
        "remove-range"
      );
      setEditorState(tempEditorState);
      return tempEditorState;
    }
    return editorState;
  };

  const _addPastedContent = (input, editorState) => {
    const inputLength = editorState.getCurrentContent().getPlainText().length;
    let remainingLength = MAX_LENGTH - inputLength;

    const newContent = Modifier.insertText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      input.slice(0, remainingLength)
    );
    setEditorState(
      EditorState.push(editorState, newContent, "insert-characters")
    );
  };

  const __handlePastedText = (pastedText) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = _getLengthOfSelectedText();

    if (
      currentContentLength + pastedText.length - selectedTextLength >
      MAX_LENGTH
    ) {
      const selection = editorState.getSelection();
      const isCollapsed = selection.isCollapsed();
      const tempEditorState = !isCollapsed ? _removeSelection() : editorState;
      _addPastedContent(pastedText, tempEditorState);

      return "handled";
    }
    return "not-handled";
  };

  useEffect(() => {
    if (defaultValue) {
      setEditorState(convertHTMLToEditorState(defaultValue));
    }
  }, [defaultValue]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={convertEditorStateToHTML(editorState)}
      render={({ field: { onChange, onBlur } }) => (
        <Box
          sx={{ p: 1, border: "1px solid rgba(0, 0, 0, 0.3)", borderRadius: 1 }}
        >
          {name === "reason_for_tr" ? (
            <Editor
              ref={editorRef}
              placeholder={label}
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              handleBeforeInput={__handleBeforeInput}
              handlePastedText={__handlePastedText}
              onEditorStateChange={(newState) => {
                setEditorState(newState);
                const htmlValue = convertEditorStateToHTML(newState);
                onChange(htmlValue); // Pass updated HTML value
              }}
              onBlur={onBlur}
            />
          ) : (
            <Editor
              ref={editorRef}
              placeholder={label}
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(newState) => {
                setEditorState(newState);
                const htmlValue = convertEditorStateToHTML(newState);
                onChange(htmlValue); // Pass updated HTML value
              }}
              onBlur={onBlur}
            />
          )}
          {name === "reason_for_tr" && (
            <div>
              <FormHelperText>
                {currentCount}/{MAX_LENGTH} characters
              </FormHelperText>
            </div>
          )}
          {errors[name] && (
            <FormHelperText error>{errors[name].message}</FormHelperText>
          )}
        </Box>
      )}
    />
  );
};

export default DraftWysiwygEditor;
