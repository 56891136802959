import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";

function FrequentHostWidget(props) {
  const { frequent_hosts } = props;
  const categories = frequent_hosts.map((item) => item.host || "None");
  const series = [
    {
      name: "Count",
      data: frequent_hosts.map((item) => item.count),
    },
  ];

  const [awaitRender, setAwaitRender] = useState(true);
  const theme = useTheme();

  const chartOptions = {
    chart: {
      animations: {
        speed: 400,
        animateGradually: {
          enabled: false,
        },
      },
      fontFamily: "inherit",
      foreColor: "inherit",
      height: "100%",
      type: "bar",
      toolbar: {
        show: true,
      },
      sparkline: {
        enabled: false,
      },
    },
    xaxis: {
      categories,
      title: "Program Type",
    },
    yaxis: {
      title: {
        text: "Count",
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
      },
    },
    // colors: ["#DD6B20", "#F6AD55"],
    // colors: ['#319795', '#4FD1C5'],
    // colors: ['#3182CE', '#63B3ED'],
    stroke: {
      colors: [theme.palette.background.paper],
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
      active: {
        filter: {
          type: "none",
        },
      },
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      theme: "dark",
      // custom: ({ seriesIndex, w }) =>
      //   `<div class="flex items-center h-32 min-h-32 max-h-23 px-12">
      //       <div class="w-12 h-12 rounded-full" style="background-color: ${w.config.colors[seriesIndex]};"></div>
      //       <div class="ml-8 text-md leading-none">${w.config.labels[seriesIndex]}:</div>
      //       <div class="ml-8 text-md font-bold leading-none">${w.config.series[seriesIndex]}%</div>
      //   </div>`,
    },
  };

  useEffect(() => {
    setAwaitRender(false);
  }, []);

  if (awaitRender) {
    return null;
  }
  return (
    <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden p-24 min-h-256">
      <div className="flex flex-col sm:flex-row items-start justify-between">
        <Typography className="text-lg font-medium tracking-tight leading-6 truncate">
          Frequent Hosts
        </Typography>
        <div className="ml-8">
          <Chip
            size="small"
            className="font-medium text-sm"
            label="This year"
          />
        </div>
      </div>

      <div className="flex flex-col flex-auto mt-12 pb-32 h-256">
        <ReactApexChart
          className="flex flex-auto items-center justify-center w-full h-full"
          options={chartOptions}
          series={series}
          type={chartOptions.chart.type}
          height={chartOptions.chart.height}
        />
      </div>
    </Paper>
  );
}

export default FrequentHostWidget;
