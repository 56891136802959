import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import format from "date-fns/format";
import Divider from "@mui/material/Divider";
import { red } from "@mui/material/colors";
import {
  ListItem,
  ListItemText,
  Box,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  CardActions,
  Button,
  Grid,
} from "@mui/material";
import Pagination from "../Pagination";
import { backend_url } from "../Constants";
import NavLinkAdapter from "../NavLinkAdapter";

function AfterTripsList(props) {
  const { token } = props;
  const [trips, setTrips] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = trips.count;
  const links = trips.links;
  const totalPages = Math.ceil(totalItems / 50);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/traveller/after_trip/?` +
          new URLSearchParams({
            trc_p: currentPage,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setTrips(data));
    }

    fetchData();
  }, [token, currentPage]);

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
      className="flex flex-col flex-auto w-full max-h-full m-8"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="sm:px-12 py-1 sm:py-3 sm:my-3 sm:mt-32">
          <motion.div
            variants={item}
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex flex-col flex-auto w-full max-h-full m-0 sm:m-8"
          >
            <List className="w-full m-0 p-0" disablePadding>
              <ListItem className="sm:px-12 py-3 sm:mt-3 mt-32">
                <ListItemText
                  classes={{
                    root: "m-0",
                    primary: "font-medium leading-5 truncate",
                  }}
                  primary={
                    <Typography component="h5" variant="h5">
                      {trips.count} After trip documents
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <Divider />
          </motion.div>

          <Grid container spacing={0}>
            {trips &&
              trips.count > 0 &&
              trips.results.map((after_trips) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={after_trips.after_trip_id}
                  >
                    <motion.div variants={item}>
                      <Card
                        key={after_trips.after_trip_id}
                        sx={{ maxWidth: 345, marginTop: 2 }}
                      >
                        <CardHeader
                          component={NavLinkAdapter}
                          to={`/${after_trips.trip.trip_id}`}
                          sx={{ textDecoration: "none" }}
                          avatar={
                            <Avatar
                              src={
                                window.location.hostname === "localhost"
                                  ? `http://localhost:8000/${after_trips.user.profile_photo}`
                                  : `https://fotimsb.headofpublicservice.go.ke/${after_trips.user.profile_photo}`
                              }
                              sx={{ bgcolor: red[500] }}
                              aria-label="user profile photo"
                            />
                          }
                          title={`${
                            after_trips.user.salutation !== undefined &&
                            after_trips.user.salutation
                          }.  ${after_trips.user.first_name} ${
                            after_trips.user.last_name
                          }`}
                          subheader={format(
                            new Date(after_trips.created_at),
                            "dd-MMM-yyyy"
                          )}
                        />
                        {/* <CardMedia
                          component="img"
                          height="194"
                          image={`${
                            window.location.hostname === "localhost"
                              ? `http://localhost:8000${after_trips.ticket_up}`
                              : `https://fotimsb.headofpublicservice.go.ke${after_trips.ticket_up}`
                          }`}
                          alt="Ticket upload"
                        /> */}
                        <CardContent>
                          <Typography
                            component="h6"
                            variant="h6"
                            className="sm:flex sm:flex-auto sm:items-left sm:text-left"
                          >
                            {after_trips.trip.trip_type === "public"
                              ? after_trips.trip.program_title
                              : "Private Travel"}
                          </Typography>
                          <Typography variant="p" className="font-bold">
                            Country: {after_trips.trip.country}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ color: "text.primary" }}
                          >
                            Ticket cost: {after_trips.ticket_cost}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ color: "text.primary" }}
                          >
                            Accomodation cost: {after_trips.accomo_cost}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ color: "text.primary" }}
                          >
                            Participation cost: {after_trips.participant_cost}
                          </Typography>
                          <CardActions disableSpacing>
                            <div className="flex flex-col">
                              <div className="flex flex-row">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  sx={{ pr: 2, my: 1, mr: 2 }}
                                  component={NavLinkAdapter}
                                  target="_blank"
                                  to={`${
                                    window.location.hostname === "localhost"
                                      ? `http://localhost:8000${after_trips.ticket_up}`
                                      : `https://fotimsb.headofpublicservice.go.ke${after_trips.ticket_up}`
                                  }`}
                                >
                                  View Ticket
                                </Button>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  sx={{ pr: 2, my: 1, mr: 2 }}
                                  component={NavLinkAdapter}
                                  target="_blank"
                                  to={`${
                                    window.location.hostname === "localhost"
                                      ? `http://localhost:8000${after_trips.passport_up}`
                                      : `https://fotimsb.headofpublicservice.go.ke${after_trips.passport_up}`
                                  }`}
                                >
                                  View Passport
                                </Button>
                              </div>
                              <div className="flex flex-row">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  sx={{ pr: 2, my: 1, mr: 2 }}
                                  component={NavLinkAdapter}
                                  target="_blank"
                                  to={`${
                                    window.location.hostname === "localhost"
                                      ? `http://localhost:8000${after_trips.report_up}`
                                      : `https://fotimsb.headofpublicservice.go.ke${after_trips.report_up}`
                                  }`}
                                >
                                  View Report
                                </Button>
                                <Button
                                  component={NavLinkAdapter}
                                  to={`/${after_trips.trip.trip_id}`}
                                  variant="contained"
                                  color="success"
                                  sx={{ pr: 2, my: 1, mr: 2 }}
                                  size="large"
                                >
                                  View Trip
                                </Button>
                              </div>
                            </div>
                          </CardActions>
                        </CardContent>
                      </Card>
                    </motion.div>
                  </Grid>
                );
              })}
          </Grid>

          {trips && trips.count > 0 && (
            <div className="my-16 mx-8">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                previousPage={links.previous}
                nextPage={links.next}
              />
            </div>
          )}
        </div>
      </Box>
    </motion.div>
  );
}

export default AfterTripsList;
