import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Typography,
  Box,
  Grid,
  Autocomplete,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import _ from "../../@lodash";
import { min_state_inst } from "../institutions";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import format from "date-fns/format";

const FilterApplications = ({ filters, setFilters }) => {
  const [selectedMinistry, setSelectedMinistry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedInstitution, setSelectedInstitution] = useState("");
  const [filteredMinistry, setFilteredMinistry] = useState([]);
  const [filteredState, setFilteredState] = useState([]);
  const [filteredInstitution, setFilteredInstitution] = useState([]);

  const handleMinistryChange = (newValue) => {
    if (newValue) {
      const filteredMinistries = min_state_inst.filter(
        (item) => item.Ministry === newValue
      );

      setFilteredMinistry(filteredMinistries);
      setSelectedMinistry(newValue);

      setFilters((prev) => ({
        ...prev,
        ministry: newValue,
      }));
    } else {
      setFilteredMinistry(min_state_inst);
      setSelectedMinistry(null);
      setFilters((prev) => ({
        ...prev,
        ministry: "",
      }));
    }
  };

  const handleStateChange = (newValue) => {
    const selectedState = newValue;

    if (newValue) {
      // Filter the data based on the selected Ministry
      const filteredStates =
        selectedState === "None"
          ? min_state_inst.filter((item) => item.Ministry === selectedMinistry)
          : min_state_inst.filter(
              (item) => item.State_Departments === selectedState
            );

      setFilteredState(filteredStates);
      setSelectedState(selectedState);

      setFilters((prev) => ({
        ...prev,
        State_departments: newValue,
      }));
    } else {
      setFilteredState(min_state_inst);
      setSelectedState(null);
      setFilters((prev) => ({
        ...prev,
        State_departments: "",
      }));
    }
  };

  const handleInstitutionChange = (newValue) => {
    const selectedInstitution = newValue;

    if (newValue) {
      // Filter the data based on the selected Ministry
      const filteredStates =
        selectedInstitution === "None"
          ? min_state_inst.filter((item) => item.Ministry === selectedMinistry)
          : min_state_inst.filter(
              (item) => item.institutions === selectedInstitution
            );

      setFilteredInstitution(filteredStates);
      setSelectedInstitution(selectedInstitution);

      setFilters((prev) => ({
        ...prev,
        institutions: newValue,
      }));
    } else {
      // Handle clearing the filter
      setFilteredInstitution(min_state_inst);
      setSelectedInstitution(null);
      setFilters((prev) => ({
        ...prev,
        institutions: "",
      }));
    }
  };

  const ministries = _.uniqWith(
    min_state_inst.map((min) => min.Ministry),
    _.isEqual
  );

  const department =
    filteredMinistry &&
    // choices.state_departments !== undefined &&
    _.uniqWith(
      filteredMinistry.map((department) => department.State_Departments),
      _.isEqual
    );

  const institution =
    filteredState &&
    // choices.institution !== undefined &&
    _.uniqWith(
      filteredState.map((institution) => institution.Institutions),
      _.isEqual
    );

  const handleDateChange = (field) => (newValue) => {
    setFilters((prev) => ({
      ...prev,
      //   [field]: newValue ? newValue.toISOString().split("T")[0] : "",
      [field]: newValue ? format(new Date(newValue), "yyyy-MM-dd") : "",
    }));
  };

  const handleChange = (field) => (event) => {
    setFilters({ ...filters, [field]: event.target.value });
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="filter-content"
        id="filter-header"
        sx={{
          // marginLeft: "-48px",
          // marginRight: "-48px",
          backgroundColor: "#fdf3da",
          "& .MuiAccordionSummary-content": {
            color: "black", // Optional: Change text color for better contrast
          },
        }}
      >
        <div className="mx-0">
          <Typography variant="h6">Filter Applications</Typography>
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                sx={{ mb: 2 }}
                id="ministry"
                fullWidth
                options={Array.from(
                  new Set(min_state_inst.map((item) => item.Ministry))
                )
                  .map((ministry) => ministry)
                  .sort()}
                value={filters.ministry || null} // Ensure a controlled component
                onChange={(event, newValue) => {
                  handleMinistryChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Ministry" variant="outlined" />
                )}
              />
            </Grid>

            {selectedMinistry && (
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  sx={{ mb: 2 }}
                  id="State_departments"
                  fullWidth
                  options={department.sort()}
                  value={filters.State_departments || null} // Ensure a controlled component
                  onChange={(event, newValue) => {
                    handleStateChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State Department"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            )}
            {selectedState && (
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  sx={{ mb: 2 }}
                  id="institutions"
                  fullWidth
                  options={institution.sort()}
                  value={filters.institution || null} // Ensure a controlled component
                  onChange={(event, newValue) => {
                    handleInstitutionChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Institution"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Airline (e.g) Kenya Airways"
                variant="outlined"
                value={filters.commuter_name}
                onChange={handleChange("commuter_name")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="flex flex-row">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of departure start"
                    clearable
                    value={filters.date_of_dep_start || null}
                    onChange={handleDateChange("date_of_dep_start")}
                    slotProps={{
                      textField: {
                        id: "date_of_dep_start",
                        label: "Date of departure start",
                        InputLabelProps: {
                          shrink: true,
                        },
                        fullWidth: true,
                        variant: "outlined",
                      },
                      actionBar: {
                        actions: ["clear", "today"],
                      },
                    }}
                  />
                  <div className="mx-16 my-4">
                    <Typography component="h6">_</Typography>
                  </div>

                  <DatePicker
                    label="Date of departure end"
                    clearable
                    value={filters.date_of_dep_end || null}
                    onChange={handleDateChange("date_of_dep_end")}
                    slotProps={{
                      textField: {
                        id: "date_of_dep_end",
                        label: "Date of departure end",
                        InputLabelProps: {
                          shrink: true,
                        },
                        fullWidth: true,
                        variant: "outlined",
                      },
                      actionBar: {
                        actions: ["clear", "today"],
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <div className="flex flex-row">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of return start"
                    clearable
                    value={filters.date_of_return_start || null}
                    onChange={handleDateChange("date_of_return_start")}
                    slotProps={{
                      textField: {
                        id: "date_of_return_start",
                        label: "Date of return start",
                        InputLabelProps: {
                          shrink: true,
                        },
                        fullWidth: true,
                        variant: "outlined",
                      },
                      actionBar: {
                        actions: ["clear", "today"],
                      },
                    }}
                  />
                  <div className="mx-16 my-4">
                    <Typography component="h6">_</Typography>
                  </div>

                  <DatePicker
                    label="Date of return end"
                    clearable
                    value={filters.date_of_return_end || null}
                    onChange={handleDateChange("date_of_return_end")}
                    slotProps={{
                      textField: {
                        id: "date_of_return_end",
                        label: "Date of return end",
                        InputLabelProps: {
                          shrink: true,
                        },
                        fullWidth: true,
                        variant: "outlined",
                      },
                      actionBar: {
                        actions: ["clear", "today"],
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </Grid>
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default FilterApplications;
