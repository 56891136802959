import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import QuizIcon from "@mui/icons-material/Quiz";
import LogoutIcon from "@mui/icons-material/Logout";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { Link } from "react-router-dom";

export const DrawerList = (props) => {
  const { user } = props;

  return (
    <div>
      <Typography
        variant="p"
        component="p"
        className="font-bold px-8 pt-4"
        color="secondary.dark"
      >
        DASHBOARD
      </Typography>

      <List>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="p" component="p" className="font-medium">
                  Home
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>

        {user &&
          user.designation !== "MIN_FOREIGN" &&
          user.designation !== "HOPS_CHECKING" &&
          user.designation !== "HOPS_CONFIRMATION" &&
          user.designation !== "HE" && (
            <>
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/form">
                  <ListItemIcon>
                    <ConnectingAirportsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="p" className="font-medium">
                        Official Travel Application
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/private-trip">
                  <ListItemIcon>
                    <ConnectingAirportsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="p" className="font-medium">
                        Private Travel
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </>
          )}

        {user && user.designation === "HOPS_CHECKING" && (
          <>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/reports">
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="p" className="font-medium">
                      Reports
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
              component={Link} to="/after-trip-uploads"
              >
                <ListItemIcon>
                  <DriveFolderUploadIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="p" className="font-medium">
                      After Trip Uploads
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          </>
        )}

        <ListItem disablePadding>
          <ListItemButton component={Link} to="/sign-out">
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="p" className="font-medium">
                  Sign Out
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>

        <Divider sx={{ my: 2 }} />

        <ListItem disablePadding>
          <ListItemButton component={Link} to="/faq">
            <ListItemIcon>
              <QuizIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="p" className="font-medium">
                  FAQs
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
};
