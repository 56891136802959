import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "../../@lodash";
import { lighten } from "@mui/material/styles";
import TravelSnackbar from "../TravelSnackbar";
import Loading from "../Loading";
import { backend_url } from "../Constants";

const schema = yup.object().shape({
  ticket_up: yup.mixed().required(),
  passport_up: yup.mixed().required(),
  report_up: yup.mixed().required(),
  whats_achieved: yup.string().notRequired(),
  accomo_cost: yup.number().required(),
  ticket_cost: yup.number().required(),
  participant_cost: yup.number().required(),
});

const defaultValues = {
  ticket_up: null,
  passport_up: null,
  report_up: null,
  whats_achieved: null,
  accomo_cost: null,
  ticket_cost: null,
  participant_cost: null,
};

export default function Uploads(props) {
  const { token, open, setOpen, trip_id } = props;
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  function submit({
    ticket_up,
    passport_up,
    report_up,
    whats_achieved,
    accomo_cost,
    ticket_cost,
    participant_cost,
  }) {
    const uploadInfo = {
      ticket_up,
      passport_up,
      report_up,
      whats_achieved,
      accomo_cost,
      ticket_cost,
      participant_cost,
      trip: trip_id,
    };
    axios
      .put(
        `${backend_url}/traveller/after_trip/`,
        uploadInfo,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Uploaded Successfully.");
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(
          err.code === "ERR_NETWORK" && "Network error, please try again later."
        );
        setMessage(err.response.data?.details);
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <div className="py-4 w-full max-w-3xl">
            <Typography
              component="h6"
              variant="p"
              className="px-4 py-16 text-lg font-semibold"
            >
              Please Upload your documents
            </Typography>

            <form
              name="uploadInfo"
              noValidate
              className="flex flex-col justify-center w-full mt-32"
              onSubmit={handleSubmit(submit)}
            >
              {openMessage === true && (
                <TravelSnackbar
                  openMessage={openMessage}
                  severity={severity}
                  message={message}
                  uploads={true}
                />
              )}

              <div className="sm:flex flex-col -mx-4">
                <Controller
                  control={control}
                  name="ticket_up"
                  render={({ field: { onChange, value } }) => (
                    <Box
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? lighten(theme.palette.background.default, 1)
                            : lighten(theme.palette.background.default, 0.02),
                      }}
                      // component="button_ticket_up"
                      // htmlFor="button_ticket_up"
                      className="flex flex-col items-center justify-center relative w-82 h-64 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
                    >
                      <Typography
                        variant="p"
                        component="p"
                        className="text-xs font-small pl-8 pr-16"
                      >
                        <span> Actual ticket costs</span>
                      </Typography>
                      <div>
                        {/* <IconButton>
                          <FileUploadIcon sx={{ color: "action" }} />
                        </IconButton> */}
                        <input
                          accept=".jpeg, .jpg, .png, .pdf,"
                          // className="hidden"
                          id="button_ticket_up"
                          type="file"
                          onChange={(e) => onChange(e.target.files[0])}
                        />
                      </div>
                    </Box>
                  )}
                />

                <Controller
                  control={control}
                  name="passport_up"
                  render={({ field: { onChange, value } }) => (
                    <Box
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? lighten(theme.palette.background.default, 1)
                            : lighten(theme.palette.background.default, 0.02),
                      }}
                      // component="label"
                      // htmlFor="button_passport_up"
                      className="flex flex-col items-center justify-center relative w-82 h-64 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
                    >
                      <Typography
                        variant="p"
                        component="p"
                        className="text-xs font-small pl-8 pr-16"
                      >
                        <span> Passport Upload</span>
                        {/* {value === null ? (
                          <span> Passport Upload</span>
                        ) : (
                          // value.name
                          ""
                        )} */}
                      </Typography>

                      <div>
                        {/* <IconButton>
                          <FileUploadIcon sx={{ color: "action" }} />
                        </IconButton> */}

                        <input
                          accept=".jpeg, .jpg, .png, .pdf,"
                          // className="hidden"
                          id="button_passport_up"
                          type="file"
                          onChange={(e) => onChange(e.target.files[0])}
                        />
                      </div>
                    </Box>
                  )}
                />

                <Controller
                  control={control}
                  name="report_up"
                  render={({ field: { onChange, value } }) => (
                    <Box
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? lighten(theme.palette.background.default, 1)
                            : lighten(theme.palette.background.default, 0.02),
                      }}
                      // component="button_report_up"
                      // htmlFor="button_report_up"
                      className="flex flex-col items-center justify-center relative  w-82 h-64  rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
                    >
                      <Typography
                        variant="p"
                        component="p"
                        className="text-xs font-small pl-8 pr-16"
                      >
                        <span> A detailed report Upload</span>
                      </Typography>
                      <div>
                        {/* <IconButton>
                          <FileUploadIcon sx={{ color: "action" }} />
                        </IconButton> */}

                        <input
                          accept=".jpeg, .jpg, .png, .pdf,"
                          // className="hidden"
                          id="button_report_up"
                          type="file"
                          onChange={(e) => onChange(e.target.files[0])}
                        />
                      </div>
                    </Box>
                  )}
                />
              </div>

              <div className="sm:flex flex-col m-4">
                <Controller
                  name="accomo_cost"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Actual accommodation and subsistence costs"
                      id="accomo_cost"
                      error={!!errors.accomo_cost}
                      helperText={errors?.accomo_cost?.message}
                      variant="standard"
                      sx={{ p: 1 }}
                      fullWidth
                    />
                  )}
                />

                <Controller
                  name="ticket_cost"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Actual ticket costs"
                      id="applicant_role"
                      error={!!errors.ticket_cost}
                      helperText={errors?.ticket_cost?.message}
                      variant="standard"
                      sx={{ p: 1 }}
                      fullWidth
                    />
                  )}
                />
              </div>

              <div className="sm:flex m-4">
                <Controller
                  name="participant_cost"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Participation fee"
                      id="participant_cost"
                      error={!!errors.participant_cost}
                      helperText={errors?.participant_cost?.message}
                      variant="standard"
                      sx={{ p: 1 }}
                      fullWidth
                    />
                  )}
                />
              </div>

              <div className="flex flex-row justify-between m-4">
                <div>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                </div>

                <div>
                  {(_.isEmpty(dirtyFields) || !isValid) && (
                    <Typography variant="caption" fontWeight="bold" color="red">
                      {" "}
                      Please fill in all fields
                    </Typography>
                  )}
                  <Button
                    sx={{ mx: 2 }}
                    variant="contained"
                    color="success"
                    disabled={_.isEmpty(dirtyFields) || !isValid}
                    onClick={() => {
                      setOpenMessage(false);
                      setLoading(Object.keys(errors).length > 0 ? false : true);
                    }}
                    type="submit"
                  >
                    {loading === true ? (
                      <Loading />
                    ) : Object.keys(errors).length > 0 ? (
                      "Complete All Fields"
                    ) : Object.keys(errors).length > 0 ? (
                      setLoading(false)
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
