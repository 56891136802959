import {
  Document,
  Packer,
  Paragraph,
  Table as DocTable,
  TableRow as DocRow,
  TableCell as DocCell,
} from "docx";

import { saveAs } from "file-saver";
import format from "date-fns/format";
import { Button } from "@mui/material";
import { convert } from "html-to-text";

export const DownloadSummary = ({
  trip,
  id,
  daysRounded,
  depRet,
  delLength,
  sumsKES,
  sums,
  tot_cost_dollars,
}) => {
  const reasonForTravelPlainText = convert(trip.reason_for_tr, {
    wordwrap: false, // Keep lines intact without wrapping
  });

  const generateDocx = () => {
    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              text: `Travel Clearance Request Summary for Trip ${id}`,
              heading: "Title",
            }),
            new DocTable({
              rows: [
                new DocRow({
                  children: [
                    new DocCell({
                      children: [new Paragraph("Application Ref: No")],
                    }),
                    new DocCell({ children: [new Paragraph(`${id}`)] }),
                  ],
                }),
                new DocRow({
                  children: [
                    new DocCell({
                      children: [new Paragraph("Date of Application:")],
                    }),
                    new DocCell({
                      children: [
                        new Paragraph(
                          format(new Date(trip.created_at), "dd-MMM-yyyy")
                        ),
                      ],
                    }),
                  ],
                }),
                new DocRow({
                  children: [
                    new DocCell({
                      children: [new Paragraph("Date Received:")],
                    }),
                    new DocCell({
                      children: [
                        new Paragraph(
                          format(new Date(trip.updated_at), "dd-MMM-yyyy")
                        ),
                      ],
                    }),
                  ],
                }),
                new DocRow({
                  children: [
                    new DocCell({ children: [new Paragraph("Applicant:")] }),
                    new DocCell({
                      children: [
                        new Paragraph(
                          `${trip.user.salutation} ${trip.user.first_name} ${trip.user.last_name}`
                        ),
                      ],
                    }),
                  ],
                }),
                new DocRow({
                  children: [
                    new DocCell({ children: [new Paragraph("Designation:")] }),
                    new DocCell({
                      children: [new Paragraph(trip.user.designation)],
                    }),
                  ],
                }),
                new DocRow({
                  children: [
                    new DocCell({ children: [new Paragraph("MDA:")] }),
                    new DocCell({
                      children: [new Paragraph(trip.user.ministry)],
                    }),
                  ],
                }),
                new DocRow({
                  children: [
                    new DocCell({
                      children: [new Paragraph("Days Requested:")],
                    }),
                    new DocCell({
                      children: [new Paragraph(`${daysRounded} days`)],
                    }),
                  ],
                }),
                new DocRow({
                  children: [
                    new DocCell({ children: [new Paragraph("Dates:")] }),
                    new DocCell({
                      children: [
                        new Paragraph(
                          `${format(
                            new Date(depRet.date_of_dep),
                            "dd-MMM-yyyy"
                          )} - ${format(
                            new Date(depRet.date_of_return),
                            "dd-MMM-yyyy"
                          )}`
                        ),
                      ],
                    }),
                  ],
                }),
                new DocRow({
                  children: [
                    new DocCell({
                      children: [
                        new Paragraph(
                          "No. of Days Remaining on Applicants Docket:"
                        ),
                      ],
                    }),
                    new DocCell({
                      children: [
                        new Paragraph(`${15 - trip.days_this_quarter}`),
                      ],
                    }),
                  ],
                }),
                new DocRow({
                  children: [
                    new DocCell({
                      children: [new Paragraph("Purpose for travel:")],
                    }),
                    new DocCell({
                      children: [new Paragraph(`${reasonForTravelPlainText}`)],
                    }),
                  ],
                }),
                new DocRow({
                  children: [
                    new DocCell({ children: [new Paragraph("Destination:")] }),
                    new DocCell({ children: [new Paragraph(trip.country)] }),
                  ],
                }),
                new DocRow({
                  children: [
                    new DocCell({
                      children: [new Paragraph("Delegation Nos:")],
                    }),
                    new DocCell({
                      children: [new Paragraph(`${delLength}`)],
                    }),
                  ],
                }),
                new DocRow({
                  children: [
                    new DocCell({
                      children: [
                        new Paragraph("Participation Fee & Ticket Cost(KES):"),
                      ],
                    }),
                    new DocCell({
                      children: [
                        new Paragraph(
                          `${
                            trip.hops_checking_confirm === "not_confirmed"
                              ? sums[sums.length - 1].toLocaleString()
                              : sums[sums.length - 1].toLocaleString()
                          }`
                        ),
                      ],
                    }),
                  ],
                }),

                new DocRow({
                  children: [
                    new DocCell({
                      children: [
                        new Paragraph(
                          `${
                            trip.hops_checking_confirm === "not_confirmed"
                              ? "Accomodation Cost($):"
                              : "Accomodation Cost(KES):"
                          }`
                        ),
                      ],
                    }),
                    new DocCell({
                      children: [
                        new Paragraph(
                          `${
                            trip.hops_checking_confirm === "not_confirmed"
                              ? tot_cost_dollars
                                  .reduce((acc, current) => acc + current, 0)
                                  .toLocaleString()
                              : (
                                  sumsKES[sumsKES.length - 1] -
                                  sums[sums.length - 1]
                                ).toLocaleString()
                          }`
                        ),
                      ],
                    }),
                  ],
                }),

                new DocRow({
                  children: [
                    new DocCell({
                      children: [new Paragraph("Total Cost(KES):")],
                    }),
                    new DocCell({
                      children: [
                        new Paragraph(
                          `${
                            trip.hops_checking_confirm !== "not_confirmed"
                              ? sumsKES[sumsKES.length - 1].toLocaleString()
                              : []
                          }`
                        ),
                      ],
                    }),
                  ],
                }),

                new DocRow({
                  children: [
                    new DocCell({
                      children: [new Paragraph("Sponsor:")],
                    }),
                    new DocCell({
                      children: [new Paragraph(`${trip.sponsor}`)],
                    }),
                  ],
                }),

                new DocRow({
                  children: [
                    new DocCell({
                      children: [new Paragraph("Sponsor:")],
                    }),
                    new DocCell({
                      children: [new Paragraph(`${trip.sponsor}`)],
                    }),
                  ],
                }),
              ],
            }),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `Travel_Clearance_Summary_Trip_${id}.docx`);
    });
  };

  return (
    <div className="mb-16">
      <Button variant="contained" color="primary" onClick={generateDocx}>
        Download as Word Document
      </Button>
    </div>
  );
};
