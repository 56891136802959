import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DownloadIcon from "@mui/icons-material/Download";
import { backend_url } from "../../components/Constants";
import Loading from "../../components/Loading";
import {
  Autocomplete,
  DialogContentText,
  Grid,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { min_state_inst } from "../../components/institutions";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import _ from "../../@lodash";
import format from "date-fns/format";

function downloadReportAsExcel(trips) {
  const tot_cost_kes = [];
  const tot_ticket_cost_kes = [];

  // Initialize an array to hold all mapped trips
  const mappedTrips = [];

  // Process each entry
  trips.forEach((entry) => {
    if (!entry.trips || entry.trips.length === 0) {
      console.warn("No trips found for entry:", entry);
      return; // Skip this entry if no trips are present
    }

    const entryMappedTrips = entry.trips
      .map((trip, detailIndex) => {
        const {
          trip_data,
          delegate_data = [],
          depature_and_return_data = [],
        } = trip;

        // Validate trip_data and user_data
        if (!trip_data || trip_data.length === 0) {
          console.warn("Missing trip_data for trip at index:", detailIndex);
          return null; // Skip this trip
        }
        if (!entry.user_data || entry.user_data.length === 0) {
          console.warn("Missing user_data for entry:", entry);
          return null; // Skip this trip
        }

        const {
          full_name,
          designation,
          ministry,
          State_departments,
          institutions,
          job_group,
        } = entry.user_data[0] || {};

        const days =
          depature_and_return_data.length > 0
            ? (new Date(depature_and_return_data[0]?.date_of_return).getTime() -
                new Date(depature_and_return_data[0]?.date_of_dep).getTime()) /
              (1000 * 3600 * 24)
            : 1;
        const days_rounded = Math.ceil(days);

        // Calculate exchange rate based on accommodation and GOK costs
        const exchange_rate =
          (trip_data[0]?.accomo_cost || 0) /
          (trip_data[0]?.gok_cost || 1) /
          days_rounded;

        // Track costs
        if (trip_data[0]?.accomo_cost) {
          tot_cost_kes.push(parseInt(trip_data[0].accomo_cost, 10));
        }

        delegate_data.forEach((delegate) => {
          if (delegate.accomo_cost) {
            tot_cost_kes.push(
              parseInt(delegate.accomo_cost * exchange_rate, 10)
            );
          }
        });

        if (trip_data[0]?.ticket_cost) {
          tot_ticket_cost_kes.push(parseInt(trip_data[0].ticket_cost, 10));
        }

        delegate_data.forEach((delegate) => {
          if (delegate.ticket_cost) {
            tot_ticket_cost_kes.push(parseInt(delegate.ticket_cost, 10));
          }
        });

        const sumsKES = tot_cost_kes.reduce(
          (a, x, i) => [...a, x + (i && a[i - 1])],
          []
        );
        const sumsTicketKES = tot_ticket_cost_kes.reduce(
          (a, x, i) => [...a, x + (i && a[i - 1])],
          []
        );

        // Map trip to desired structure
        return {
          "S/No": trip_data[0]?.trip_id || "",
          "Departure Date": trip_data[0]?.program_from || "",
          "Name of Applicant": full_name || "",
          Designation: designation || "",
          Ministry: ministry || "",
          "State Department": State_departments || "",
          "State Corporation/Agency": institutions || "",
          "Number of Days": days_rounded || 0,
          "Job Group": job_group || "",
          Destination: trip_data[0]?.country || "",
          Airline: trip_data[0]?.host || "",
          "Number of Delegates": delegate_data.length || 0,
          "Ticket Costs": sumsTicketKES[detailIndex] || 0, // Cumulative ticket cost
          DSA: sumsKES[detailIndex] || 0, // Cumulative accommodation cost
          Sponsor: trip_data[0]?.sponsor || "",
          Decision: trip_data[0]?.chief_confirm || "",
        };
      })
      .filter(Boolean); // Remove any null or invalid results

    // Append to the overall mappedTrips array
    mappedTrips.push(...entryMappedTrips);
  });

  // Flatten the results
  // console.log("Flattened Trips:", mappedTrips);

  // Flatten the mapped data
  // console.log("mappedTrip:", mappedTrips);
  const flattenedData = mappedTrips.flat();

  // Create a new workbook and worksheet
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(flattenedData);

  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "All_Trip_Reports");

  // Generate Excel file and download
  XLSX.writeFile(workbook, "All_Trip_Reports.xlsx");
}

export default function DownloadStats({ token }) {
  const [open, setOpen] = useState(false);
  const [download, setDownload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState();
  const [filters, setFilters] = useState([]);

  const [selectedMinistry, setSelectedMinistry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedInstitution, setSelectedInstitution] = useState("");
  const [filteredMinistry, setFilteredMinistry] = useState([]);
  const [filteredState, setFilteredState] = useState([]);
  const [filteredInstitution, setFilteredInstitution] = useState([]);

  const handleMinistryChange = (newValue) => {
    if (newValue) {
      const filteredMinistries = min_state_inst.filter(
        (item) => item.Ministry === newValue
      );

      setFilteredMinistry(filteredMinistries);
      setSelectedMinistry(newValue);

      setFilters((prev) => ({
        ...prev,
        ministry: newValue,
      }));
    } else {
      setFilteredMinistry(min_state_inst);
      setSelectedMinistry(null);
      setFilters((prev) => ({
        ...prev,
        ministry: "",
      }));
    }
  };

  const handleStateChange = (newValue) => {
    const selectedState = newValue;

    if (newValue) {
      // Filter the data based on the selected Ministry
      const filteredStates =
        selectedState === "None"
          ? min_state_inst.filter((item) => item.Ministry === selectedMinistry)
          : min_state_inst.filter(
              (item) => item.State_Departments === selectedState
            );

      setFilteredState(filteredStates);
      setSelectedState(selectedState);

      setFilters((prev) => ({
        ...prev,
        State_departments: newValue,
      }));
    } else {
      setFilteredState(min_state_inst);
      setSelectedState(null);
      setFilters((prev) => ({
        ...prev,
        State_departments: "",
      }));
    }
  };

  const handleInstitutionChange = (newValue) => {
    const selectedInstitution = newValue;

    if (newValue) {
      // Filter the data based on the selected Ministry
      const filteredStates =
        selectedInstitution === "None"
          ? min_state_inst.filter((item) => item.Ministry === selectedMinistry)
          : min_state_inst.filter(
              (item) => item.institutions === selectedInstitution
            );

      setFilteredInstitution(filteredStates);
      setSelectedInstitution(selectedInstitution);

      setFilters((prev) => ({
        ...prev,
        institutions: newValue,
      }));
    } else {
      // Handle clearing the filter
      setFilteredInstitution(min_state_inst);
      setSelectedInstitution(null);
      setFilters((prev) => ({
        ...prev,
        institutions: "",
      }));
    }
  };

  const ministries = _.uniqWith(
    min_state_inst.map((min) => min.Ministry),
    _.isEqual
  );

  const department =
    filteredMinistry &&
    // choices.state_departments !== undefined &&
    _.uniqWith(
      filteredMinistry.map((department) => department.State_Departments),
      _.isEqual
    );

  const institution =
    filteredState &&
    // choices.institution !== undefined &&
    _.uniqWith(
      filteredState.map((institution) => institution.Institutions),
      _.isEqual
    );

  const handleDateChange = (field) => (newValue) => {
    setFilters((prev) => ({
      ...prev,
      //   [field]: newValue ? newValue.toISOString().split("T")[0] : "",
      [field]: newValue ? format(new Date(newValue), "yyyy-MM-dd") : "",
    }));
  };

  const handleChange = (field) => (event) => {
    setFilters({ ...filters, [field]: event.target.value });
  };

  const fetchData = async (url, params, token) => {
    setLoading(true);
    try {
      const response = await fetch(`${url}?${new URLSearchParams(params)}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setReport(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    const queryParams = {
      limit:
        Object.keys(filters).length > 0 ? (download === true ? 2000 : 10) : 10,
      offset: 0,
      trc_p: 1,
      ...filters,
    };

    fetchData(`${backend_url}/traveller/alldata/`, queryParams, token);
  }, [backend_url, token, filters, download]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        className="whitespace-nowrap"
        variant="contained"
        color="secondary"
        aria-label="Download Stats"
        startIcon={<DownloadIcon size={20} />}
        onClick={handleClickOpen}
      >
        Download Report
      </Button>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Download Trip Reports</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To download, please filter to your desired liking.
          </DialogContentText>

          <Box sx={{ width: "100%", my: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  sx={{ mb: 2 }}
                  id="ministry"
                  fullWidth
                  options={Array.from(
                    new Set(min_state_inst.map((item) => item.Ministry))
                  )
                    .map((ministry) => ministry)
                    .sort()}
                  value={filters.ministry || null} // Ensure a controlled component
                  onChange={(event, newValue) => {
                    handleMinistryChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Ministry"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              {selectedMinistry && (
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    sx={{ mb: 2 }}
                    id="State_departments"
                    fullWidth
                    options={department.sort()}
                    value={filters.State_departments || null} // Ensure a controlled component
                    onChange={(event, newValue) => {
                      handleStateChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="State Department"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              )}
              {selectedState && (
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    sx={{ mb: 2 }}
                    id="institutions"
                    fullWidth
                    options={institution.sort()}
                    value={filters.institution || null} // Ensure a controlled component
                    onChange={(event, newValue) => {
                      handleInstitutionChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Institution"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  label="Airline (e.g) Kenya Airways"
                  variant="outlined"
                  value={filters.commuter_name}
                  onChange={handleChange("commuter_name")}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <div className="flex flex-row">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of departure start"
                      clearable
                      value={filters.date_of_dep_start || null}
                      onChange={handleDateChange("date_of_dep_start")}
                      slotProps={{
                        textField: {
                          id: "date_of_dep_start",
                          label: "Date of departure start",
                          InputLabelProps: {
                            shrink: true,
                          },
                          fullWidth: true,
                          variant: "outlined",
                        },
                        actionBar: {
                          actions: ["clear", "today"],
                        },
                      }}
                    />
                    <div className="mx-16 my-4">
                      <Typography component="h6">_</Typography>
                    </div>

                    <DatePicker
                      label="Date of departure end"
                      clearable
                      value={filters.date_of_dep_end || null}
                      onChange={handleDateChange("date_of_dep_end")}
                      slotProps={{
                        textField: {
                          id: "date_of_dep_end",
                          label: "Date of departure end",
                          InputLabelProps: {
                            shrink: true,
                          },
                          fullWidth: true,
                          variant: "outlined",
                        },
                        actionBar: {
                          actions: ["clear", "today"],
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>

              <Grid item xs={12} sm={12}>
                <div className="flex flex-row">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of return start"
                      clearable
                      value={filters.date_of_return_start || null}
                      onChange={handleDateChange("date_of_return_start")}
                      slotProps={{
                        textField: {
                          id: "date_of_return_start",
                          label: "Date of return start",
                          InputLabelProps: {
                            shrink: true,
                          },
                          fullWidth: true,
                          variant: "outlined",
                        },
                        actionBar: {
                          actions: ["clear", "today"],
                        },
                      }}
                    />
                    <div className="mx-16 my-4">
                      <Typography component="h6">_</Typography>
                    </div>

                    <DatePicker
                      label="Date of return end"
                      clearable
                      value={filters.date_of_return_end || null}
                      onChange={handleDateChange("date_of_return_end")}
                      slotProps={{
                        textField: {
                          id: "date_of_return_end",
                          label: "Date of return end",
                          InputLabelProps: {
                            shrink: true,
                          },
                          fullWidth: true,
                          variant: "outlined",
                        },
                        actionBar: {
                          actions: ["clear", "today"],
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <div className="flex justify-between p-16">
          <div className="flex items-left justify-left">
            <Button onClick={handleClose} variant="contained" color="error">
              Cancel
            </Button>
          </div>
          {report !== undefined ? (
            <div className="items-end justify-end">
              {!loading ? (
                <Button
                  onClick={() => {
                    setDownload(true);
                    downloadReportAsExcel(report.results.map((entry) => entry));
                  }}
                  type="submit"
                  variant="contained"
                  color="success"
                >
                  Download Reports as a CSV
                </Button>
              ) : (
                <Loading />
              )}
            </div>
          ) : (
            <Loading />
          )}
        </div>
      </Dialog>
    </React.Fragment>
  );
}
