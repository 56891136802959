import React, { useState } from "react";
import * as yup from "yup";
// import _ from "../../@lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import format from "date-fns/format";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { lighten } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Loading from "../../components/Loading";
import TravelSnackbar from "../../components/TravelSnackbar";
import { countries, countries_with_Kenya } from "../../components/Countries";
import { backend_url } from "../../components/Constants";
import DraftWysiwygEditor from "../editor/TextEditor";

export default function Part1(props) {
  const { user, token, handleNext, trip_id } = props;
  const [similar, setSimilar] = useState(null);
  const [virtualityPresent, setVirtualityPresent] = useState(null);
  const [bench, setBench] = useState(false);
  const [program_type1, setProgram_type1] = useState(false);
  const [program_type2, setProgram_type2] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const countrys = countries && countries.map((countries) => countries.Country);
  const countrys_incl_Ke =
    countries_with_Kenya &&
    countries_with_Kenya.map((countries) => countries.Country);

  const schema = yup.object().shape({
    reason_for_tr: yup.string().required("Please state the reason for travel"),
    program_type: yup
      .string()
      .oneOf(
        [
          "conference",
          "seminar",
          "workshop",
          "meeting",
          "benchmarking",
          "assignment",
          "goverment to government",
          "other",
        ],
        "Please select the program type"
      ),
    country: yup
      .string()
      .required("You must enter the country")
      .oneOf(countrys !== false ? countrys : [], "Please select the country"),
    host_country: yup
      .string()
      .required("You must enter the country")
      .oneOf(
        countrys_incl_Ke !== false ? countrys_incl_Ke : [],
        "Please select the country"
      ),
    program_similar_country:
      similar === true
        ? yup
            .string()
            .required("You must enter the country")
            .oneOf(
              countrys !== false ? countrys : [],
              "Please select the country"
            )
        : yup.string().notRequired(),
    program_title: yup.string().required("Please state the program title"),
    program_from: yup
      .date()
      .required("You must enter date of program")
      .default(() => new Date()),
    program_to: yup
      .date()
      .required("You must enter date of program")
      .default(() => new Date()),
    similar: yup
      .string()
      .required("Please fill in this field")
      .oneOf(["true", "false"]),
    program_similar:
      similar === true
        ? yup.string().required("Please state the program title")
        : yup.string().notRequired(),
    prog_sm_from:
      similar === true
        ? yup
            .date()
            .required("Please state the dates")
            .default(() => new Date())
        : yup.date().notRequired(),
    prog_sm_to:
      similar === true
        ? yup
            .date()
            .required("Please state the dates")
            .default(() => new Date())
        : yup.date().notRequired(),
    program_other:
      program_type1 === true
        ? yup.string().required("Please state the name")
        : yup.string().notRequired(),
    host: yup.string().required("Please state the host"),
    host_zip_postal_code: yup
      .string()
      .required("Please state the zip or postal code"),
    host_city: yup.string().required("Please state the city"),
    sponsor:
      bench === true
        ? yup.string().required("Please state the sponsor")
        : yup.string().notRequired(),
    sponsor_status1: yup
      .string()
      .required("Please state the trip sponsorship status")
      .oneOf(
        ["fully", "fully1", "not_sponsored"],
        "Please select the sponsorship status"
      ),
    object_meeting: yup.string().required("Please state the objectives"),
    exp_activity: yup.string().required("Please state the expected activity"),
    virtualityPresent: yup.boolean().required("This field is required"),
    // virtualityPresent: yup.string().required().oneOf(["true", "false"]),
    virtuality_reason:
      virtualityPresent === true
        ? yup.string().required("This field is required")
        : yup.string().notRequired(),

    virtuality_upload:
      virtualityPresent === true
        ? yup.mixed().required("Please upload the virtual letter")
        : yup.string().notRequired(),
    // accomo_cost: yup
    //   .number()
    //   .required("This field is required, if none type 0"),
    // ticket_cost: yup
    //   .number()
    //   .required("This field is required, if none type 0"),
    // participant_cost: yup
    //   .number()
    //   .required("This field is required, if none type 0"),
    invitation_letter_up: yup
      .mixed()
      .required("Please upload the invitation letter"),
    account_up: yup
      .mixed()
      .required("Please upload the accountant letter upload"),
    procument_up: yup
      .mixed()
      .required("Please upload the procurement letter upload"),
    program_up: yup.mixed().required("Please upload the program letter upload"),
    meeting_up:
      program_type2 === true
        ? yup.mixed().required("Please upload the gazette notice")
        : yup.mixed().notRequired(),
  });

  const defaultValues = {
    invitation_letter_up: null,
    meeting_up: null,
    program_up: null,
    account_up: null,
    virtuality_upload: null,
    procument_up: null,
    virtuality_reason: null,
    reason_for_tr: "",
    program_type: "",
    program_title: "",
    program_from: null,
    program_to: null,
    program_similar: "",
    prog_sm_from: null,
    prog_sm_to: null,
    program_other: "",
    host: "",
    host_city: "",
    host_zip_postal_code: "",
    sponsor: "",
    sponsor_status1: "",
    object_meeting: "",
    exp_activity: "",
    // accomo_cost: 0,
    // ticket_cost: 0,
    // participant_cost: 0,
  };

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    // isValid, dirtyFields,
    errors,
  } = formState;

  // const sevenDays = dayjs().set("hour", 168).startOf("hour");

  function submit({
    invitation_letter_up,
    meeting_up,
    program_up,
    account_up,
    virtuality_upload,
    procument_up,
    virtuality_reason,
    country,
    host_country,
    program_similar_country,
    reason_for_tr,
    program_type,
    program_title,
    program_from,
    program_to,
    program_similar,
    prog_sm_from,
    prog_sm_to,
    program_other,
    host,
    host_zip_postal_code,
    host_city,
    sponsor,
    sponsor_status1,
    object_meeting,
    exp_activity,
    // accomo_cost,
    // ticket_cost,
    // participant_cost,
  }) {
    const tripInfo = {
      invitation_letter_up,
      program_up,
      account_up,
      virtuality_upload: virtualityPresent === false ? null : virtuality_upload,
      procument_up,
      virtuality_reason,
      country,
      meeting_up: program_type2 === false ? null : meeting_up,
      reason_for_tr,
      program_type,
      program_title,
      program_from: format(new Date(program_from), "yyyy-MM-dd"),
      program_to: format(new Date(program_to), "yyyy-MM-dd"),
      program_similar,
      program_similar_country,
      prog_sm_from:
        prog_sm_from === null
          ? "1970-01-01"
          : format(new Date(prog_sm_from), "yyyy-MM-dd"),
      prog_sm_to:
        prog_sm_to === null
          ? "1970-01-02"
          : format(new Date(prog_sm_to), "yyyy-MM-dd"),
      program_other: program_type1 === false ? "" : program_other,
      host,
      host_zip_postal_code,
      host_city,
      host_country,
      sponsor,
      sponsor_status: sponsor_status1,
      object_meeting,
      exp_activity,
      virtuality: virtualityPresent,
      // accomo_cost,
      // ticket_cost,
      // participant_cost,
      trip_type: "public",
    };
    axios
      .put(
        `${backend_url}/traveller/details/`,
        tripInfo,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setDisabled(false);
        setSeverity("success");
        setMessage("Travel Details Submitted. Go to the next step!");
        setLoading(false);
        trip_id === undefined ? window.location.reload() : handleNext();
      })
      .catch((err) => {
        console.log(err);
        setDisabled(false);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(
          err.code === "ERR_NETWORK" && "Network error, please try again later."
        );
        setMessage(
          err.response.data.account_up
            ? err.response.data.account_up
            : err.response.data.invitation_letter_up
            ? err.response.data.invitation_letter_up
            : err.response.data.procument_up
            ? err.response.data.procument_up
            : err.response.data.program_up
            ? err.response.data.program_up
            : err.response.data?.details
            ? err.response.data.details
            : err.response.data
        );
        setLoading(false);
      });
  }

  return (
    <div>
      <form
        name="tripForm"
        noValidate
        className="flex flex-col justify-center w-full mt-8 sm:mt-32"
        onSubmit={handleSubmit(submit)}
      >
        {openMessage === true && (
          <TravelSnackbar
            openMessage={openMessage}
            severity={severity}
            message={message}
          />
        )}

        <Typography
          component="h5"
          variant="p"
          className="px-4 py-16 text-md md:text-lg font-semibold sm:font-bold"
        >
          PERSONAL DETAILS
        </Typography>

        <div className="sm:flex sm:-mx-4">
          <TextField
            label="Name of Officer Travelling:"
            id="officer_name"
            variant="filled"
            sx={{ p: 1 }}
            InputProps={{
              readOnly: true,
            }}
            defaultValue={user.full_name}
            fullWidth
          />

          <TextField
            label="Personal No:"
            id="personal_no"
            variant="filled"
            sx={{ p: 1 }}
            InputProps={{
              readOnly: true,
            }}
            defaultValue={user.personal_no}
            fullWidth
          />
        </div>

        <div className="sm:flex sm:-mx-4">
          <TextField
            label="Designation:"
            id="designation"
            variant="filled"
            InputProps={{
              readOnly: true,
            }}
            sx={{ p: 1 }}
            defaultValue={user.designation}
            fullWidth
          />

          <TextField
            label="Ministry/Corporation:"
            id="ministry_corp"
            variant="filled"
            InputProps={{
              readOnly: true,
            }}
            sx={{ p: 1 }}
            defaultValue={user.ministry}
            fullWidth
          />
        </div>

        <div className="sm:flex sm:-mx-4">
          <TextField
            label="Passport No:"
            id="passport_no"
            variant="filled"
            InputProps={{
              readOnly: true,
            }}
            sx={{ p: 1 }}
            // fullWidth
            defaultValue={user.passport_no}
          />
        </div>

        {/* Part 2 */}
        <Typography
          component="h5"
          variant="p"
          className="px-4 py-16 text-md font-semibold md:text-lg md:font-bold"
        >
          TRAVEL DETAILS
        </Typography>

        <div>
          <Typography
            component="p"
            variant="p"
            className="p-8 sm:p-12 text-xs font-semibold"
          >
            If attending Conference/Seminar/Workshop, meeting/
            benchmarking/assignment/formal government to government engagement
            etc. Please indicate its title-
          </Typography>

          <Controller
            name="program_type"
            control={control}
            render={({ field }) => (
              <FormControl error={!!errors.program_type} required>
                <RadioGroup
                  row
                  aria-labelledby="radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="pt-4 pb-8 px-8"
                  {...field}
                >
                  <FormControlLabel
                    className="mx-8"
                    value="conference"
                    control={
                      <Radio
                        onClick={() => {
                          setProgram_type1(false);
                          setProgram_type2(false);
                        }}
                      />
                    }
                    label="Conference"
                  />
                  <FormControlLabel
                    className="mx-8"
                    value="seminar"
                    control={
                      <Radio
                        onClick={() => {
                          setProgram_type1(false);
                          setProgram_type2(false);
                        }}
                      />
                    }
                    label="Seminar"
                  />
                  <FormControlLabel
                    className="mx-8"
                    value="workshop"
                    control={
                      <Radio
                        onClick={() => {
                          setProgram_type1(false);
                          setProgram_type2(false);
                        }}
                      />
                    }
                    label="Workshop"
                  />
                  <FormControlLabel
                    className="mx-8"
                    value="meeting"
                    control={
                      <Radio
                        onClick={() => {
                          setProgram_type1(false);
                          setProgram_type2(false);
                        }}
                      />
                    }
                    label="Meeting"
                  />

                  {bench === true ? (
                    <FormControlLabel
                      className="mx-8"
                      value="benchmarking"
                      control={
                        <Radio
                          onClick={() => {
                            setProgram_type1(false);
                            setProgram_type2(true);
                          }}
                        />
                      }
                      label="Benchmarking"
                    />
                  ) : (
                    <div></div>
                  )}
                  <FormControlLabel
                    className="mx-8"
                    value="assignment"
                    control={
                      <Radio
                        onClick={() => {
                          setProgram_type1(false);
                          setProgram_type2(false);
                        }}
                      />
                    }
                    label="Assignment"
                  />
                  <FormControlLabel
                    className="mx-8"
                    value="goverment to government"
                    control={
                      <Radio
                        onClick={() => {
                          setProgram_type1(false);
                          setProgram_type2(false);
                        }}
                      />
                    }
                    label="Formal government to government"
                  />
                  <FormControlLabel
                    className="mx-8"
                    value="other"
                    control={
                      <Radio
                        onClick={() => {
                          setProgram_type1(true);
                          setProgram_type2(false);
                        }}
                      />
                    }
                    label="Other"
                  />
                </RadioGroup>
                <FormHelperText>
                  <span className="text-red-500">
                    {" "}
                    {errors?.program_type?.message}
                  </span>
                </FormHelperText>
              </FormControl>
            )}
          />

          <Controller
            name="program_title"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Title:"
                id="program_title"
                variant="outlined"
                sx={{ p: 1 }}
                error={!!errors.program_title}
                helperText={errors?.program_title?.message}
                multiline
                minRows={3}
                fullWidth
              />
            )}
          />

          {program_type1 === true && (
            <Controller
              name="program_other"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Name of other:"
                  id="program_other"
                  variant="outlined"
                  sx={{ p: 1 }}
                  error={!!errors.program_other}
                  helperText={errors?.program_other?.message}
                  multiline
                  minRows={2}
                  required
                  fullWidth
                />
              )}
            />
          )}

          <div className="sm:flex sm:-mx-4 mt-8">
            <Controller
              control={control}
              name="invitation_letter_up"
              render={({ field: { onChange, value } }) => (
                <Box
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light"
                        ? lighten(theme.palette.background.default, 1)
                        : lighten(theme.palette.background.default, 0.02),
                  }}
                  component="label"
                  htmlFor="button_invitation_letter_up"
                  className="flex items-center justify-center relative w-82 h-64 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
                >
                  <input
                    accept=".jpeg, .jpg, .png, .PNG, .pdf,"
                    className="hidden"
                    id="button_invitation_letter_up"
                    type="file"
                    onChange={(e) => onChange(e.target.files[0])}
                  />
                  <IconButton>
                    <FileUploadIcon sx={{ color: "action" }} />
                  </IconButton>

                  <Typography
                    variant="p"
                    component="p"
                    className="text-xs font-small pl-8 pr-16"
                  >
                    {value === null ? (
                      <span> Invitation Letter Upload</span>
                    ) : (
                      value.name
                    )}
                  </Typography>
                  <FormHelperText>
                    {" "}
                    <span className="flex flex-col text-red-500">
                      {errors?.invitation_letter_up?.message}
                    </span>
                  </FormHelperText>
                </Box>
              )}
            />

            <Controller
              control={control}
              name="program_up"
              render={({ field: { onChange, value } }) => (
                <Box
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light"
                        ? lighten(theme.palette.background.default, 1)
                        : lighten(theme.palette.background.default, 0.02),
                  }}
                  component="label"
                  htmlFor="button_program_up"
                  className="flex items-center justify-center relative  w-82 h-64  rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
                >
                  <input
                    accept=".jpeg, .jpg, .png, .PNG, .pdf,"
                    className="hidden"
                    id="button_program_up"
                    type="file"
                    onChange={(e) => onChange(e.target.files[0])}
                  />
                  <IconButton>
                    <FileUploadIcon sx={{ color: "action" }} />
                  </IconButton>

                  <Typography
                    variant="p"
                    component="p"
                    className="text-xs font-small pl-8 pr-16"
                  >
                    {value === null ? <span> Program Upload</span> : value.name}
                  </Typography>
                  <FormHelperText>
                    {" "}
                    <span className="flex flex-col text-red-500">
                      {errors?.program_up?.message}
                    </span>
                  </FormHelperText>
                </Box>
              )}
            />

            {program_type2 === true && (
              <div className="sm:flex sm:-mx-4 mt-8 sm:mt-0">
                <Controller
                  control={control}
                  name="meeting_up"
                  render={({ field: { onChange, value } }) => (
                    <Box
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? lighten(theme.palette.background.default, 1)
                            : lighten(theme.palette.background.default, 0.02),
                      }}
                      component="label"
                      htmlFor="button_meeting_up"
                      className="flex items-center justify-center relative  w-82 h-64  rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
                    >
                      <input
                        accept=".jpeg, .jpg, .png, .PNG, .pdf,"
                        className="hidden"
                        id="button_meeting_up"
                        type="file"
                        onChange={(e) => onChange(e.target.files[0])}
                      />
                      <IconButton>
                        <FileUploadIcon sx={{ color: "action" }} />
                      </IconButton>

                      <Typography
                        variant="p"
                        component="p"
                        className="text-xs font-small pl-8 pr-16"
                      >
                        {value === null ? (
                          <span> Gazette notice for benchmarking Upload</span>
                        ) : (
                          value.name
                        )}
                      </Typography>
                      <FormHelperText>
                        {" "}
                        <span className="flex flex-col text-red-500">
                          {errors?.meeting_up?.message}
                        </span>
                      </FormHelperText>
                    </Box>
                  )}
                />
              </div>
            )}
          </div>

          <div>
            <Typography
              component="p"
              variant="p"
              className="pb-12 text-xs font-small"
            >
              (Annex invitation letter addressed to applicant in person)
            </Typography>

            {program_type2 === true && (
              <Typography
                component="p"
                variant="p"
                className="pb-12 text-xs font-small"
              >
                (Annex programme. For benchmark visits, annex copy of Gazette
                Notice of Taskforce/Working Committee to which the travel is
                related)
              </Typography>
            )}
          </div>
        </div>

        <div>
          {/* <Controller
            control={control}
            name="reason_for_tr"
            defaultValue=""
            rules={{
              validate: (value) => {
                if (!value) return "Reason for travel is required.";
                if (countWords(value) > 50) {
                  return "Reason for travel must not exceed 50 words.";
                }
                return true;
              },
            }}
            render={({ field }) => (
              // <TextField />
              <DraftWysiwygEditor
                control={control}
                {...field}
                label="Reason(s) for travel:"
                errors={errors}
              />
            )}
          />
          {errors.reason_for_tr && (
            <p style={{ color: "red" }}>{errors.reason_for_tr.message}</p>
          )} */}

          <DraftWysiwygEditor
            control={control}
            name="reason_for_tr"
            label="Reason(s) for travel:"
            errors={errors}
            defaultValue=""
          />

          {/* <Controller
            name="reason_for_tr"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Reason(s) for travel: "
                id="reason_for_tr"
                variant="outlined"
                sx={{ p: 1 }}
                error={!!errors.reason_for_tr}
                helperText={errors?.reason_for_tr?.message}
                multiline
                minRows={4}
                fullWidth
              />
            )}
          /> */}
        </div>

        <div>
          {countries && (
            <Controller
              name="country"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  sx={{ p: 1 }}
                  id="country"
                  fullWidth
                  options={countrys}
                  value={value}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      helperText={errors?.country?.message}
                      error={!!errors.country}
                      {...params}
                      label="Country"
                    />
                  )}
                />
              )}
            />
          )}
        </div>

        <div>
          <Typography
            component="p"
            variant="p"
            className="p-8 sm:p-12 text-xs font-semibold"
          >
            Sponsor(s) of the Meeting/ Engagement/ Conference/ Seminar/
            Workshop, etc:
          </Typography>

          <Controller
            name="sponsor_status1"
            control={control}
            render={({ field }) => (
              <FormControl
                sx={{ p: 1 }}
                fullWidth
                error={!!errors.sponsor_status1}
              >
                <InputLabel id="sponsor_status1">Sponsor Cost</InputLabel>
                <Select
                  labelId="sponsor_status1"
                  id="sponsor_status1"
                  label="Sponsor Cost"
                  {...field}
                >
                  <MenuItem
                    value={"fully"}
                    onClick={() => {
                      setBench(true);
                    }}
                  >
                    GOK Sponsored
                  </MenuItem>
                  <MenuItem
                    value={"not_sponsored"}
                    onClick={() => {
                      setBench(true);
                    }}
                  >
                    Non-GOK/ Host Sponsored
                  </MenuItem>
                  {/* <MenuItem
                    value={"not_sponsored"}
                    onClick={() => setBench(false)}
                  >
                    Not Sponsored
                  </MenuItem> */}
                </Select>
              </FormControl>
            )}
          />
          {bench === true && (
            <Controller
              name="sponsor"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Sponsor Entity/Institute/Organization:"
                  id="sponsor"
                  variant="outlined"
                  error={!!errors.sponsor}
                  helperText={errors?.sponsor?.message}
                  sx={{ p: 1 }}
                  fullWidth
                  multiline
                  minRows={3}
                />
              )}
            />
          )}
        </div>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="sm:flex m-4">
            <Controller
              name="program_from"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  sx={{ py: 2 }}
                  // minDate={sevenDays}
                  clearable
                  slotProps={{
                    textField: {
                      id: "program_from",
                      label: "Program from date",
                      error: !!errors.program_from,
                      helperText: errors?.program_from?.message,
                      InputLabelProps: {
                        shrink: true,
                      },
                      fullWidth: true,
                      variant: "outlined",
                    },
                    actionBar: {
                      actions: ["clear", "today"],
                    },
                  }}
                />
              )}
            />

            <span className="p-16"></span>
            <Controller
              name="program_to"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  sx={{ py: 2 }}
                  // minDate={sevenDays}
                  clearable
                  slotProps={{
                    textField: {
                      id: "program_to",
                      label: "Program to date",
                      error: !!errors.program_to,
                      helperText: errors?.program_to?.message,
                      InputLabelProps: {
                        shrink: true,
                      },
                      fullWidth: true,
                      variant: "outlined",
                    },
                    actionBar: {
                      actions: ["clear", "today"],
                    },
                  }}
                />
              )}
            />
          </div>
        </LocalizationProvider>

        <div>
          <Typography
            component="p"
            variant="p"
            className="p-8 sm:p-12 text-xs font-semibold"
          >
            Please indicate if you have attended a similar or related conference
            on the same subject matter in the last five (5) years-
          </Typography>

          <Controller
            name="similar"
            control={control}
            render={({ field }) => (
              <FormControl error={!!errors.similar} required>
                <RadioGroup
                  row
                  aria-labelledby="radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="pt-4 pb-8 px-8"
                  {...field}
                >
                  <FormControlLabel
                    className="mx-8"
                    value="true"
                    control={
                      <Radio
                        onClick={() => {
                          setSimilar(true);
                        }}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    className="mx-8"
                    value="false"
                    control={
                      <Radio
                        onClick={() => {
                          setSimilar(false);
                        }}
                      />
                    }
                    label="No"
                  />
                </RadioGroup>
                <FormHelperText>
                  <span className="text-red-500">
                    {" "}
                    {errors?.similar?.message}
                  </span>
                </FormHelperText>
              </FormControl>
            )}
          />
        </div>

        {similar === true && (
          <>
            <DraftWysiwygEditor
              control={control}
              name="program_similar"
              label="Title and describe more on what the conference was about"
              errors={errors}
              defaultValue=""
            />

            {/* <Controller
              name="program_similar"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Title and describe more on what the conference was about"
                  id="program_similar"
                  variant="outlined"
                  sx={{ p: 1 }}
                  error={!!errors.program_similar}
                  helperText={errors?.program_similar?.message}
                  multiline
                  minRows={3}
                  fullWidth
                  required={similar === true ? true : false}
                />
              )}
            /> */}

            <div className="py-8 mx-4">
              {countries !== undefined && (
                <Controller
                  name="program_similar_country"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      sx={{ p: 1 }}
                      id="program_similar_country"
                      fullWidth
                      options={countrys}
                      value={value}
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          helperText={errors?.program_similar_country?.message}
                          error={!!errors.program_similar_country}
                          {...params}
                          label="Country"
                        />
                      )}
                    />
                  )}
                />
              )}
            </div>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className="sm:flex m-4">
                <Controller
                  name="prog_sm_from"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      sx={{ py: 2 }}
                      clearable
                      slotProps={{
                        textField: {
                          id: "prog_sm_from",
                          label: "Similar program start date",
                          error: !!errors.prog_sm_from,
                          helperText: errors?.prog_sm_from?.message,
                          InputLabelProps: {
                            shrink: true,
                          },
                          fullWidth: true,
                          variant: "outlined",
                        },
                        actionBar: {
                          actions: ["clear", "today"],
                        },
                      }}
                    />
                  )}
                />

                <span className="p-16"></span>

                <Controller
                  name="prog_sm_to"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      sx={{ py: 2 }}
                      clearable
                      slotProps={{
                        textField: {
                          id: "prog_sm_to",
                          label: "Similar program end date",
                          error: !!errors.prog_sm_to,
                          helperText: errors?.prog_sm_to?.message,
                          InputLabelProps: {
                            shrink: true,
                          },
                          fullWidth: true,
                          variant: "outlined",
                        },
                        actionBar: {
                          actions: ["clear", "today"],
                        },
                      }}
                    />
                  )}
                />
              </div>
            </LocalizationProvider>
          </>
        )}

        <div>
          <Typography
            component="p"
            variant="p"
            className="p-8 sm:p-12 text-xs font-semibold"
          >
            Person(s) Organization/ Institution hosting the Meeting/ Engagement/
            Conference/ Seminar/ Workshop, etc.
          </Typography>

          <Controller
            name="host"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Name of the host / organization/ institution:"
                id="host"
                variant="outlined"
                error={!!errors.host}
                helperText={errors?.host?.message}
                sx={{ p: 1 }}
                fullWidth
                multiline
                minRows={3}
              />
            )}
          />

          <div className="sm:flex m-4">
            <Controller
              name="host_city"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="City of the host"
                  id="host_city"
                  variant="outlined"
                  error={!!errors.host_city}
                  helperText={errors?.host_city?.message}
                  sx={{ p: 1 }}
                  fullWidth
                />
              )}
            />

            <Controller
              name="host_zip_postal_code"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ZIP/Postal code of the host"
                  id="host_zip_postal_code"
                  variant="outlined"
                  error={!!errors.host_zip_postal_code}
                  helperText={errors?.host_zip_postal_code?.message}
                  sx={{ p: 1 }}
                  fullWidth
                />
              )}
            />
          </div>

          <div className="py-8 mx-4">
            {countries !== undefined && (
              <Controller
                name="host_country"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    sx={{ p: 1 }}
                    id="host_country"
                    fullWidth
                    options={countrys_incl_Ke}
                    value={value}
                    onChange={(event, newValue) => {
                      onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        helperText={errors?.host_country?.message}
                        error={!!errors.host_country}
                        {...params}
                        label="Country of the host"
                      />
                    )}
                  />
                )}
              />
            )}
          </div>
        </div>

        <div>
          <Typography
            component="p"
            variant="p"
            className="p-8 sm:p-12 text-xs font-semibold"
          >
            Objective(s) of the Meeting/ Conference/ Seminar/ Workshop, etc:
          </Typography>

          <DraftWysiwygEditor
            control={control}
            name="object_meeting"
            label="Objectives:"
            errors={errors}
            defaultValue=""
          />

          {/* <Controller
            name="object_meeting"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Objectives:"
                id="object_meeting"
                variant="outlined"
                error={!!errors.object_meeting}
                helperText={errors?.object_meeting?.message}
                sx={{ p: 1 }}
                fullWidth
                multiline
                minRows={3}
              />
            )}
          /> */}
        </div>

        <div>
          <Typography
            component="p"
            variant="p"
            className="p-8 sm:p-12 text-xs font-semibold"
          >
            Expected activity/ event/ intervention to be executed upon return in
            the Ministry/ Department/ Corporation of the participant derived
            from the Meeting/ Engagement/ Conference/ Seminar/ Workshop, etc.
          </Typography>

          <DraftWysiwygEditor
            control={control}
            name="exp_activity"
            label="Expected Activity:"
            errors={errors}
            defaultValue=""
          />

          {/* <Controller
            name="exp_activity"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Expected Activity:"
                id="exp_activity"
                variant="outlined"
                sx={{ p: 1 }}
                error={!!errors.exp_activity}
                helperText={errors?.exp_activity?.message}
                fullWidth
                multiline
                minRows={3}
              />
            )}
          /> */}
        </div>

        <div>
          <Typography
            component="p"
            variant="p"
            className="p-8 sm:p-12 text-xs font-semibold"
          >
            Where the reason for travel is a conference, workshop, academic
            meeting, confirm that the option of virtual participation is
            unavailable, and that physical attendance is mandatory.
          </Typography>

          <Controller
            name="virtualityPresent"
            control={control}
            render={({ field }) => (
              <FormControl error={!!errors.virtualityPresent} required>
                <RadioGroup
                  className="px-4"
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  {...field}
                >
                  <FormControlLabel
                    sx={{ p: 1 }}
                    value="true"
                    control={
                      <Radio
                        onClick={() => {
                          setVirtualityPresent(true);
                        }}
                      />
                    }
                    label="Virtual participation available"
                  />
                  <FormControlLabel
                    sx={{ p: 1 }}
                    value="false"
                    control={
                      <Radio
                        onClick={() => {
                          setVirtualityPresent(false);
                        }}
                      />
                    }
                    label="Virtual participation unavailable"
                  />
                </RadioGroup>
                <FormHelperText>
                  <span className="text-red-500">
                    {" "}
                    {errors?.virtualityPresent?.message}
                  </span>
                </FormHelperText>
              </FormControl>
            )}
          />

          <div className="sm:flex flex-col sm:-mx-4 mt-8">
            {virtualityPresent === true && (
              <>
                <DraftWysiwygEditor
                  control={control}
                  name="virtuality_reason"
                  label="Explain further on why you must attend:"
                  errors={errors}
                  defaultValue=""
                />

                {/* <Controller
                  name="virtuality_reason"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Explain further on why you must attend:"
                      id="virtuality_reason"
                      variant="outlined"
                      sx={{ p: 1 }}
                      error={!!errors.virtuality_reason}
                      helperText={errors?.virtuality_reason?.message}
                      fullWidth
                      multiline
                      minRows={3}
                    />
                  )}
                /> */}

                <Controller
                  control={control}
                  name="virtuality_upload"
                  render={({ field: { onChange, value } }) => (
                    <Box
                      sx={{
                        marginTop: "24px",
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? lighten(theme.palette.background.default, 1)
                            : lighten(theme.palette.background.default, 0.02),
                      }}
                      component="label"
                      htmlFor="button_virtuality_upload"
                      className="flex items-center justify-center relative w-82 h-64 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
                    >
                      <input
                        accept=".jpeg, .jpg, .png, .PNG, .pdf,"
                        className="hidden"
                        id="button_virtuality_upload"
                        type="file"
                        onChange={(e) => onChange(e.target.files[0])}
                      />
                      <IconButton>
                        <FileUploadIcon sx={{ color: "action" }} />
                      </IconButton>

                      <Typography
                        variant="p"
                        component="p"
                        className="text-xs font-small pl-8 pr-16"
                      >
                        {value === null ? (
                          <span> Virtual Letter Upload</span>
                        ) : (
                          value.name
                        )}
                      </Typography>
                      <FormHelperText>
                        {" "}
                        <span className="flex flex-col text-red-500">
                          {errors?.virtuality_upload?.message}
                        </span>
                      </FormHelperText>
                    </Box>
                  )}
                />
              </>
            )}
          </div>
        </div>

        <Typography
          component="p"
          variant="p"
          className="p-8 sm:p-12 text-xs font-semibold"
        >
          Upload the accountant and procurement letter approving the costs of
          this trip. Templates can be downloaded below.
        </Typography>

        <div className="sm:flex sm:-mx-4 mt-8">
          <Controller
            control={control}
            name="account_up"
            render={({ field: { onChange, value } }) => (
              <Box
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? lighten(theme.palette.background.default, 1)
                      : lighten(theme.palette.background.default, 0.02),
                }}
                component="label"
                htmlFor="button_account_up"
                className="flex items-center justify-center relative w-82 h-64 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
              >
                <input
                  accept=".jpeg, .jpg, .png, .PNG, .pdf,"
                  className="hidden"
                  id="button_account_up"
                  type="file"
                  onChange={(e) => onChange(e.target.files[0])}
                />
                <IconButton>
                  <FileUploadIcon sx={{ color: "action" }} />
                </IconButton>

                <Typography
                  variant="p"
                  component="p"
                  className="text-xs font-small pl-8 pr-16"
                >
                  {value === null ? (
                    <span> Accountant Letter Upload</span>
                  ) : (
                    value.name
                  )}
                </Typography>
                <FormHelperText>
                  {" "}
                  <span className="flex flex-col text-red-500">
                    {errors?.account_up?.message}
                  </span>
                </FormHelperText>
              </Box>
            )}
          />

          <Controller
            control={control}
            name="procument_up"
            render={({ field: { onChange, value } }) => (
              <Box
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? lighten(theme.palette.background.default, 1)
                      : lighten(theme.palette.background.default, 0.02),
                }}
                component="label"
                htmlFor="button_procument_up"
                className="flex items-center justify-center relative  w-82 h-64  rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
              >
                <input
                  accept=".jpeg, .jpg, .png, .PNG, .pdf,"
                  className="hidden"
                  id="button_procument_up"
                  type="file"
                  onChange={(e) => onChange(e.target.files[0])}
                />
                <IconButton>
                  <FileUploadIcon sx={{ color: "action" }} />
                </IconButton>

                <Typography
                  variant="p"
                  component="p"
                  className="text-xs font-small pl-8 pr-16"
                >
                  {value === null ? (
                    <span> Procurement Letter Upload</span>
                  ) : (
                    value.name
                  )}
                </Typography>
                <FormHelperText>
                  {" "}
                  <span className="flex flex-col text-red-500">
                    {errors?.procument_up?.message}
                  </span>
                </FormHelperText>
              </Box>
            )}
          />
        </div>

        <Button
          sx={{ mt: 4 }}
          variant="contained"
          color="success"
          disabled={loading === true ? loading : false}
          // disabled={_.isEmpty(dirtyFields) || !isValid}
          // disabled={disabled}
          onClick={() => {
            setDisabled(true);
            setOpenMessage(false);
            setLoading(
              Object.keys(errors).length === 0
                ? false
                : Object.keys(errors).length > 0
                ? false
                : true
            );
          }}
          type="submit"
        >
          {loading === true ? (
            <Loading />
          ) : Object.keys(errors).length > 0 ? (
            "Complete All Fields"
          ) : Object.keys(errors).length > 0 ? (
            setLoading(false)
          ) : (
            "Next"
          )}
        </Button>
      </form>
    </div>
  );
}
