import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import DashLayout from "../../components/Layout/DashLayout";
import ChairpersonHome from "./ChairpersonHome";
import ChairpersonHeader from "./ChairpersonHeader";
import ChairpersonForms from "./ChairpersonForms";
import ChairpersonSidebarContent from "./ChairpersonSidebarContent";
import ChairpersonApplications from "./ChairpersonApplications";
import { useMediaQuery, useTheme } from "@mui/material";
import ChairpersonApprovalHistory from "./ChairpersonApprovalHistory";
import WithoutUploadDocuments from "../../components/afterTrips/WithoutUploadDocuments";

const Root = styled(DashLayout)(({ theme }) => ({
  "& .DashLayout-header": {
    backgroundColor: theme.palette.background.paper,
    boxShadow: `inset 0 0 0 1px  ${theme.palette.divider}`,
  },
}));

function ChairpersonDash(props) {
  const { token, user } = props;
  const [tabValue, setTabValue] = useState(0);

  const pageLayout = useRef(null);
  const routeParams = useParams();
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  useEffect(() => {
    setRightSidebarOpen(Boolean(routeParams.id));
  }, [routeParams]);

  function handleChangeTab(event, value) {
    setTabValue(value);
  }

  return (
    <Root
      ref={pageLayout}
      header={
        <ChairpersonHeader pageLayout={pageLayout} token={token} user={user} />
      }
      content={
        <div className="w-full p-2 py-4 sm:py-8 lg:ltr:pr-0 lg:rtl:pl-0">
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="inherit"
            variant="scrollable"
            scrollButtons={false}
            className="w-full px-24 -mx-4 min-h-40"
            classes={{
              indicator: "flex justify-center bg-transparent w-full h-full",
            }}
            TabIndicatorProps={{
              children: (
                <Box
                  sx={{ bgcolor: "text.disabled" }}
                  className="w-full h-full rounded-full opacity-20"
                />
              ),
            }}
          >
            <Tab
              className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
              disableRipple
              label="Home"
            />
            <Tab
              className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
              disableRipple
              label="My Travel Applications"
            />
            <Tab
              className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
              disableRipple
              label="Confirm Travel Forms"
            />
            <Tab
              className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
              disableRipple
              label="Approval History"
            />
            <Tab
              className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
              disableRipple
              label="Uploads After Trip"
            />
          </Tabs>
          {tabValue === 0 && <ChairpersonHome token={token} user={user} />}
          {tabValue === 1 && <ChairpersonApplications token={token} />}
          {tabValue === 2 && <ChairpersonForms token={token} user={user} />}
          {tabValue === 3 && (
            <ChairpersonApprovalHistory token={token} user={user} />
          )}
          {/* {tabValue === 4 && <Uploads token={token} user={user} />} */}
          {tabValue === 4 && (
            <WithoutUploadDocuments token={token} user={user} />
          )}
        </div>
      }
      rightSidebarContent={<ChairpersonSidebarContent />}
      rightSidebarOpen={rightSidebarOpen}
      rightSidebarOnClose={() => setRightSidebarOpen(false)}
      rightSidebarWidth={isMobile ? 760 : window.innerWidth / 1}
      scroll={isMobile ? "normal" : "content"}
    />
  );
}

export default ChairpersonDash;
