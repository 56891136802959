import { Paper, Divider, Box, Button } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery, useTheme } from "@mui/material";

import { FormHeaderDetail } from "../../forms/details/FormHeader";
import { PersonalDetails } from "../../forms/details/Personal";
import { TravelDetails } from "../../forms/details/Travel";
import { DepartureReturnDetails } from "../../forms/details/Departure_Return";
import { DelegationSizeDetails } from "../../forms/details/Delegation";
// import { ConcurrenceDetails } from '../../forms/details/Concurrence';
// import { ApprovalDetails } from '../../forms/details/Approval';
import NavLinkAdapter from "../../components/NavLinkAdapter";
import ApprovedForm from "../../components/ApprovedForm";
// import { ConfirmationDetails } from "../../forms/details/Confirmation";
import { Private } from "../../forms/details/Private";
import { backend_url } from "../../components/Constants";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UserForm(props) {
  const { token, setOpen } = props;
  const { id } = useParams();
  const [trip, setTrip] = useState([]);
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });
  const {
    cs_first_name,
    cs_last_name,
    digitalsignature,
    trip_data,
    acting_data,
    delegate_data,
    depature_and_return_data,
  } = trip;
  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
  };

  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setValue(0);
    setOpen(false);
  }, [location, setOpen]);

  useEffect(() => {
    fetch(
      `${backend_url}/traveller/approvals/?` +
        new URLSearchParams({
          trip: id,
        }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setTrip(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token, id]);

  return (
    <div className="relative flex flex-col flex-auto items-center px-2 sm:px-8 container mx-auto">
      <Paper className="w-full px-4 sm:px-32 py-2 sm:py-32" elevation={1}>
        {trip_data && (
          <div>
            <div className="flex flex-col justify-center w-full pt-16 sm:mt-64 mb-16">
              <Button
                className="font-semibold"
                component={NavLinkAdapter}
                to="/"
                size="large"
                color="primary"
                onClick={() => (isMobile ? setOpen(false) : setOpen(true))}
                variant="contained"
              >
                <CloseIcon />
                <span className="mx-8">Close Application</span>
              </Button>
            </div>

            <Divider />

            {trip_data[0].trip_type === "private" ? (
              <div>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {trip_data[0].reviewer === true && (
                      <Tab
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                        disableRipple
                        label="Approval Letter"
                        {...a11yProps(0)}
                      />
                    )}

                    <Tab
                      className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                      disableRipple
                      label="Private Trip Details"
                      {...a11yProps(trip_data[0].reviewer === true ? 0 + 1 : 0)}
                    />
                  </Tabs>
                </Box>
                {trip_data[0].reviewer === true && (
                  <CustomTabPanel value={value} index={0}>
                    <ApprovedForm
                      privateTrip={true}
                      cs_first_name={cs_first_name}
                      cs_last_name={cs_last_name}
                      digitalsignature={digitalsignature}
                      trip={trip_data[0]}
                      // depRet={
                      //   depature_and_return_data !== undefined
                      //     ? undefined
                      //     : depature_and_return_data[0]
                      // }
                      // delegates={delegate_data}
                    />
                  </CustomTabPanel>
                )}
                <CustomTabPanel
                  value={value}
                  index={trip_data[0].reviewer === true ? 0 + 1 : 0}
                >
                  <Private trip={trip_data[0]} />
                </CustomTabPanel>
              </div>
            ) : (
              <div>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="inherit"
                    indicatorColor="secondary"
                    variant="scrollable"
                    scrollButtons={true}
                    className="w-full px-2 sm:px-24 -mx-4 min-h-40"
                    classes={{
                      indicator:
                        "flex justify-center bg-transparent w-full h-full",
                    }}
                    TabIndicatorProps={{
                      children: (
                        <Box
                          sx={{ bgcolor: "text.disabled" }}
                          className="w-full h-full rounded-full opacity-20"
                        />
                      ),
                    }}
                  >
                    {trip_data[0].reviewer === true && (
                      <Tab
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                        disableRipple
                        label="Approval Letter"
                        {...a11yProps(0)}
                      />
                    )}
                    <Tab
                      className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                      disableRipple
                      label="Annextures and Personal Details"
                      {...a11yProps(trip_data[0].reviewer === true ? 0 + 1 : 0)}
                    />
                    <Tab
                      className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                      disableRipple
                      label="Travel Details"
                      {...a11yProps(trip_data[0].reviewer === true ? 1 + 1 : 1)}
                    />
                    <Tab
                      className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                      disableRipple
                      label="Departure and Return Details"
                      {...a11yProps(trip_data[0].reviewer === true ? 2 + 1 : 2)}
                    />
                    <Tab
                      className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                      disableRipple
                      label="Costs and Size of Delegation"
                      {...a11yProps(trip_data[0].reviewer === true ? 3 + 1 : 3)}
                    />

                    {/* <Tab
                      className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                      disableRipple
                      label="Confirmation Details"
                      {...a11yProps(4)}
                    /> */}
                  </Tabs>
                </Box>

                {trip_data[0].reviewer === true && (
                  <CustomTabPanel value={value} index={0}>
                    <ApprovedForm
                      cs_first_name={cs_first_name}
                      cs_last_name={cs_last_name}
                      digitalsignature={digitalsignature}
                      trip={trip_data[0]}
                      depRet={depature_and_return_data[0]
                      }
                      delegates={delegate_data}
                    />
                  </CustomTabPanel>
                )}

                <CustomTabPanel
                  value={value}
                  index={trip_data[0].reviewer === true ? 0 + 1 : 0}
                >
                  <FormHeaderDetail trip={trip_data[0]} />
                  <PersonalDetails
                    trip={trip_data[0]}
                    actingOfficer={acting_data[0]}
                  />
                </CustomTabPanel>

                <CustomTabPanel
                  value={value}
                  index={trip_data[0].reviewer === true ? 1 + 1 : 1}
                >
                  <TravelDetails trip={trip_data[0]} />
                </CustomTabPanel>

                <CustomTabPanel
                  value={value}
                  index={trip_data[0].reviewer === true ? 2 + 1 : 2}
                >
                  {depature_and_return_data[0] !== undefined && (
                    <DepartureReturnDetails
                      trip={trip_data[0]}
                      depRet={depature_and_return_data[0]}
                      token={token}
                    />
                  )}
                </CustomTabPanel>

                <CustomTabPanel
                  value={value}
                  index={trip_data[0].reviewer === true ? 3 + 1 : 3}
                >
                  {depature_and_return_data[0] !== undefined && (
                    <DelegationSizeDetails
                      trip={trip_data[0]}
                      depRet={depature_and_return_data[0]}
                      delegates={delegate_data}
                    />
                  )}
                </CustomTabPanel>

                {/* <CustomTabPanel value={value} index={4}>
                  <ConfirmationDetails
                    trip={trip_data[0]}
                    digitalsignature={digitalsignature}
                  />
                </CustomTabPanel> */}
              </div>
            )}

            {/* <ConfirmationDetails trip={trip} />

  <Divider sx={{ py: 2, my: 2 }} />

  <ConcurrenceDetails trip={trip} />

  <Divider sx={{ py: 2, my: 2 }} />

  <ApprovalDetails trip={trip} /> */}
          </div>
        )}

        <Divider sx={{ my: 10 }} />
      </Paper>
    </div>
  );
}
