import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { Button, ListItem, ListItemText } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination";
import { backend_url } from "../../components/Constants";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import TravelSnackbar from "../../components/TravelSnackbar";
import Loading from "../../components/Loading";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { format } from "date-fns";
import HECSHistory from "./HECSHistory";

function HEHome(props) {
  const { token } = props;
  const [trips, setTrips] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [openMessage, setOpenMessage] = useState(false);
  const [csHistory, setCSHistory] = useState([]);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [checking_confirm, setChecking_confirm] = useState(undefined);
  const [id, setID] = useState(null);

  const totalItems = trips.count;
  const links = trips.links;
  // const itemsPerPage = trips && trips.results ? trips.results.trip_data.length : []
  const trip_data = trips && trips.results ? trips.results.trip_data : [];
  const dep_return = trips && trips.results ? trips.results.dep_return : [];

  const matchedTrips = [];

  trip_data.forEach((trip) => {
    const matchingDepReturn = dep_return.find(
      (depTrip) => depTrip.trip_id === trip.trip_id
    );
    if (matchingDepReturn) {
      // If a matching trip_id is found in dep_return, add both trip_data and dep_return to matchedTrips
      matchedTrips.push({
        trip_data: trip,
        dep_return: matchingDepReturn,
      });
    } else if (trip.trip_type === "private") {
      matchedTrips.push({ trip_data: trip });
    }
  });

  const totalPages = Math.ceil(totalItems / 50);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const location = useLocation();

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/traveller/confirmation/?` +
          new URLSearchParams({
            trc_p: currentPage,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setTrips(data));
    }

    fetchData();
  }, [location, token, currentPage]);

  const navigate = useNavigate();
  if (id !== null) {
    // function submit() {
    const checkingInfo = {
      trip_id: id,
      he_confirm: checking_confirm,
      reviewer: checking_confirm === "accepted" ? true : false,
    };

    let url = `${backend_url}/traveller/confirmation/`;
    axios
      .patch(url, checkingInfo, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Confirmed Successfully!");
        setLoading(false);
        setID(null);
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(
          err.response.data?.details
            ? err.response.data?.details
            : err.response.data?.error
        );
        setLoading(false);
        setID(null);
      });
    // }
  }

  return (
    <div>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
        className="flex flex-col flex-auto w-full max-h-full m-8"
      >
        <List className="w-full m-0 p-0">
          <ListItem
            disableGutters
            className="px-12 "
            sx={{ bgcolor: "background.default" }}
          >
            <ListItemText
              primary={
                <Typography component="h5" variant="h5" className="px-0 py-2 ">
                  CS Applications Waiting For Approval
                </Typography>
              }
            />
          </ListItem>

          {openMessage === true && (
            <TravelSnackbar
              openMessage={openMessage}
              severity={severity}
              message={message}
              uploads={true}
            />
          )}

          <Divider />
        </List>

        {trip_data &&
          matchedTrips.length > 0 &&
          matchedTrips.map((trip) => {
            return (
              trip.trip_data.active_trip === true &&
              (trip.trip_data.trip_type === "public" ||
                trip.trip_data.trip_type === "private") &&
              trip.trip_data.chief_confirm === "accepted" &&
              trip.trip_data.user.designation === "CS" &&
              trip.trip_data.he_confirm === "not_confirmed" && (
                <div key={trip.trip_data.trip_id} className="relative">
                  <Card
                    elevation={0}
                    sx={{
                      maxWidth: "auto",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      margin: "4px",
                      textDecoration: "none",
                    }}
                    // component={NavLinkAdapter}
                    // to={`${trip.trip_data.trip_id}`}
                  >
                    <CardHeader
                      sx={{ mb: 0, pb: 0 }}
                      title={`${
                        trip.trip_data.user.salutation !== undefined &&
                        trip.trip_data.user.salutation
                      }. ${trip.trip_data.user.first_name} ${
                        trip.trip_data.user.last_name
                      } 
                        ${trip.trip_data.user.designation} 
                        ${
                          trip.trip_data.user.designation === "CEO"
                            ? trip.trip_data.user.institutions
                            : trip.trip_data.user.designation === "CHAIRPERSON"
                            ? trip.trip_data.user.institutions
                            : trip.trip_data.user.designation === "PS"
                            ? trip.trip_data.user.State_departments
                            : trip.trip_data.user.designation === "CS" &&
                              trip.trip_data.user.ministry
                                .toLowerCase()
                                .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                                  letter.toUpperCase()
                                )
                        }`}
                      subheader={
                        <div>
                          <Typography sx={{ pt: 2 }} paragraph variant="body2">
                            <b> Country:</b> {trip.trip_data.country}{" "}
                            <b>Duration:</b>{" "}
                            {trip.trip_data.trip_type === "public" ? (
                              <>
                                {format(
                                  new Date(trip.dep_return.date_of_dep),
                                  "dd-MMM-yyyy"
                                )}
                                {" to "}
                                {format(
                                  new Date(trip.dep_return.date_of_return),
                                  "dd-MMM-yyyy"
                                )}
                              </>
                            ) : (
                              <>
                                {format(
                                  new Date(trip.trip_data.program_from),
                                  "dd-MMM-yyyy"
                                )}
                                {" to "}
                                {format(
                                  new Date(trip.trip_data.program_to),
                                  "dd-MMM-yyyy"
                                )}
                              </>
                            )}
                          </Typography>
                        </div>
                      }
                    />
                    <CardContent sx={{ pt: 0 }}>
                      <Typography
                        paragraph
                        variant="body2"
                        sx={{ fontWeight: "bold" }}
                      >
                        Reason
                      </Typography>
                      {/* <Typography
                        paragraph
                        variant="body2"
                        // color="text.secondary"
                      >
                        {trip.trip_data.trip_type === "public"
                          ? trip.trip_data.program_title
                          : `${trip.trip_data.reason_for_tr} - Private trip`}
                      </Typography> */}
                      {trip.trip_data.trip_type === "public" ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: trip.trip_data.program_title,
                          }}
                        />
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: trip.trip_data.reason_for_tr,
                          }}
                        />
                      )}

                      <Typography
                        paragraph
                        variant="body2"
                        sx={{ fontWeight: "bold" }}
                      >
                        Recommendation/Remarks by HOPS
                      </Typography>

                      <Typography
                        paragraph
                        variant="body2"
                        // color="text.secondary"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: trip.trip_data.chief_reason,
                          }}
                        />
                        {/* {trip.trip_data.chief_reason} */}
                      </Typography>

                      <HECSHistory
                        token={token}
                        trip_user={trip.trip_data.user.user_id}
                        csHistory={csHistory}
                        setCSHistory={setCSHistory}
                      />
                    </CardContent>
                    <CardActions disableSpacing>
                      {loading === true ? (
                        <Loading />
                      ) : (
                        <>
                          <Button
                            variant="contained"
                            color="success"
                            sx={{
                              marginLeft: "4px",
                              marginRight: "4px",
                            }}
                            onClick={() => {
                              setLoading(true);
                              setID(trip.trip_data.trip_id);
                              setChecking_confirm("accepted");
                            }}
                            startIcon={<CheckIcon fontSize="inherit" />}
                          >
                            Approve
                          </Button>

                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                              setLoading(true);
                              setID(trip.trip_data.trip_id);
                              setChecking_confirm("rejected");
                            }}
                            startIcon={<CloseIcon fontSize="inherit" />}
                          >
                            Reject
                          </Button>
                        </>
                      )}
                    </CardActions>
                  </Card>
                  <Divider />
                </div>
              )
            );
          })}

        <Divider />
        {trip_data && trip_data.length > 0 && (
          <div className="my-16 mx-8">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              previousPage={links.previous}
              nextPage={links.next}
            />
          </div>
        )}
      </motion.div>
    </div>
  );
}

export default HEHome;
