import {
  Paper,
  Divider,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { useMediaQuery, useTheme } from "@mui/material";
import { FormHeaderDetail } from "../../forms/details/FormHeader";
import { PersonalDetails } from "../../forms/details/Personal";
import { TravelDetails } from "../../forms/details/Travel";
import { DepartureReturnDetails } from "../../forms/details/Departure_Return";
import { DelegationSizeDetails } from "../../forms/details/Delegation";
import NavLinkAdapter from "../../components/NavLinkAdapter";
import Loading from "../../components/Loading";
import TravelSnackbar from "../../components/TravelSnackbar";
import { Private } from "../../forms/details/Private";
import { HistandStats } from "../../forms/details/HistandStats";
import { backend_url } from "../../components/Constants";
import ApprovedForm from "../../components/ApprovedForm";
import ChairSummary from "./ChairSummary";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ChairpersonConfirmation(props) {
  const { token, setOpen, user } = props;
  const { id } = useParams();
  const [trip, setTrip] = useState([]);
  const [value, setValue] = useState(0);

  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  const {
    cs_first_name,
    cs_last_name,
    trip_data,
    acting_data,
    delegate_data,
    depature_and_return_data,
    digitalsignature,
  } = trip;

  const [chairperson_confirm, setChairperson_confirm] = useState(undefined);
  const [chairperson_reason, setChairperson_reason] = useState("");

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
  };

  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setValue(0);
    setOpen(false);
  }, [location, setOpen]);

  useEffect(() => {
    fetch(
      `${backend_url}/traveller/approvals/?` +
        new URLSearchParams({
          trip: id,
        }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setTrip(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [token, id]);

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();

    const chairpersonInfo = {
      trip_id: id,
      chairperson_confirm: chairperson_confirm,
      chairperson_reason: chairperson_reason,
      // chairperson_reason: `I ${user.first_name} ${user.last_name} ${user.designation} of ${user.ministry} confirm
      // that this form has has been reviewed. ${chairperson_reason}.`,
    };

    let url = `${backend_url}/traveller/confirmation/`;
    axios
      .patch(url, chairpersonInfo, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Confirmed Successfully!");
        setLoading(false);
        setChairperson_reason("");
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(
          err.response.data?.details
            ? err.response.data?.details
            : err.response.data?.error
        );
        setLoading(false);
      });
  };

  return (
    <div className="relative flex flex-col flex-auto items-center px-4 sm:px-8 container mx-auto">
      {openMessage === true && (
        <TravelSnackbar
          openMessage={openMessage}
          severity={severity}
          message={message}
          confirmation={true}
          isMobile={isMobile}
        />
      )}
      <Paper className="w-full px-24 sm:px-32 py-32" elevation={1}>
        {trip_data && trip_data.length > 0 && (
          <div className="flex flex-col justify-center w-full pt-16 mt-64 mb-16">
            {trip_data[0].trip_type === "private" ? (
              <div className="flex flex-col justify-center w-full">
                <Button
                  className="font-semibold"
                  component={NavLinkAdapter}
                  to="/"
                  size="large"
                  onClick={() => (isMobile ? setOpen(false) : setOpen(true))}
                  color="primary"
                  variant="contained"
                >
                  <CloseIcon />
                  <span className="mx-8">Close Application</span>
                </Button>

                <div>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      textColor="secondary"
                      indicatorColor="secondary"
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      {trip_data[0].reviewer === true && (
                        <Tab
                          className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                          disableRipple
                          label="Approval Letter"
                          {...a11yProps(0)}
                        />
                      )}

                      <Tab
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                        disableRipple
                        label="Private Trip Details"
                        {...a11yProps(
                          trip_data[0].reviewer === true ? 0 + 1 : 0
                        )}
                      />
                    </Tabs>
                  </Box>
                  {trip_data[0].reviewer === true && (
                    <CustomTabPanel value={value} index={0}>
                      <ApprovedForm
                        privateTrip={true}
                        cs_first_name={cs_first_name}
                        cs_last_name={cs_last_name}
                        digitalsignature={digitalsignature}
                        trip={trip_data[0]}
                        // depRet={depature_and_return_data[0]}
                        // delegates={delegate_data}
                      />
                    </CustomTabPanel>
                  )}
                  <CustomTabPanel
                    value={value}
                    index={trip_data[0].reviewer === true ? 0 + 1 : 0}
                  >
                    <Private editForm={true} trip={trip_data[0]} />
                  </CustomTabPanel>
                </div>

                {user && user.email !== trip_data[0].user.email && (
                  <form
                    name="chairForm"
                    noValidate
                    className="flex flex-col justify-center w-full pt-8"
                    onSubmit={handleSubmit}
                  >
                    {/* <Button
                    className="font-semibold"
                    component={NavLinkAdapter}
                    to="/"
                    size="large"
                    onClick={() => (isMobile ? setOpen(false) : setOpen(true))}
                    color="primary"
                    variant="contained"
                  >
                    <CloseIcon />
                    <span className="mx-8">Close Application</span>
                  </Button> */}
                    {trip_data[0].chairperson_confirm !== "accepted" && (
                      <div>
                        <div>
                          {trip.chairperson_confirm !== "not_confirmed" && (
                            <Typography
                              component="p"
                              variant="p"
                              className="text-xs font-bold py-6"
                            >
                              Chairperson comments:{" "}
                              <span className="pl-10 text-xs font-normal">
                                {trip.chairperson_reason}
                              </span>
                            </Typography>
                          )}

                          {trip.ps_confirm !== "not_confirmed" && (
                            <Typography
                              component="p"
                              variant="p"
                              className="text-xs font-bold py-6"
                            >
                              PS comments:{" "}
                              <span className="pl-10 text-xs font-normal">
                                {trip.ps_reasons}
                              </span>
                            </Typography>
                          )}

                          {trip.cs_confirm !== "not_confirmed" && (
                            <Typography
                              component="p"
                              variant="p"
                              className="text-xs font-bold py-6"
                            >
                              CS comments:{" "}
                              <span className="pl-10 text-xs font-normal">
                                {trip.cs_reasons}
                              </span>
                            </Typography>
                          )}

                          {trip.hops_checking_confirm !== "not_confirmed" && (
                            <>
                              <Typography
                                component="p"
                                variant="p"
                                className="text-xs font-bold py-6"
                              >
                                Reviewer comments:{" "}
                                {/* <span className="pl-10 text-xs font-normal">
                                  {trip.hops_checking_reasons}
                                </span> */}
                              </Typography>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: trip.hops_checking_reasons,
                                }}
                              />
                            </>
                          )}

                          {trip.hops_confrimation_confirm !==
                            "not_confirmed" && (
                            <Typography
                              component="p"
                              variant="p"
                              className="text-xs font-bold py-6"
                            >
                              Reviewer recommendation:{" "}
                              <span className="pl-10 text-xs font-normal">
                                {trip.hops_confrimation_reasons}
                              </span>
                            </Typography>
                          )}

                          {/* {trip.chief_confirm !== "not_confirmed" && (
                        <Typography
                          component="p"
                          variant="p"
                          className="text-xs font-bold py-6"
                        >
                          HOPS comments:{" "}
                          <span className="pl-10 text-xs font-normal">{trip.chief_reason}</span>
                        </Typography>
                      )} */}
                        </div>

                        <>
                          <TextField
                            label="Reasons for Concurring/Not Concurring and Return Application:"
                            id="approval"
                            variant="outlined"
                            sx={{ my: 4 }}
                            value={chairperson_reason}
                            multiline
                            minRows={3}
                            fullWidth
                            onChange={(e) =>
                              setChairperson_reason(e.target.value)
                            }
                          />

                          <div className="flex items-center mt-24 sm:mt-0 sm:mx-8 space-x-12">
                            <Button
                              className="whitespace-nowrap"
                              variant="contained"
                              color="error"
                              onClick={() => {
                                setChairperson_confirm("rejected");
                                setOpenMessage(false);
                              }}
                              startIcon={<DoDisturbIcon />}
                              type="submit"
                            >
                              {loading === true ? <Loading /> : "Not Concurred"}
                            </Button>

                            <Button
                              className="whitespace-nowrap"
                              variant="contained"
                              color="success"
                              onClick={() => {
                                setChairperson_confirm("accepted");
                                setOpenMessage(false);
                              }}
                              startIcon={<CheckCircleOutlineIcon />}
                              type="submit"
                            >
                              {loading === true ? <Loading /> : "Concurred"}
                            </Button>
                          </div>
                        </>
                      </div>
                    )}
                  </form>
                )}
              </div>
            ) : (
              <div>
                <div className="flex flex-col justify-center w-full ">
                  <Button
                    className="font-semibold"
                    component={NavLinkAdapter}
                    to="/"
                    size="large"
                    onClick={() => (isMobile ? setOpen(false) : setOpen(true))}
                    color="primary"
                    variant="contained"
                  >
                    <CloseIcon />
                    <span className="mx-8">Close Application</span>
                  </Button>
                </div>
                <Divider />

                <div>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      textColor="secondary"
                      indicatorColor="secondary"
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      {trip_data[0].reviewer === true && (
                        <Tab
                          className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                          disableRipple
                          label="Approval Letter"
                          {...a11yProps(0)}
                        />
                      )}

                      <Tab
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                        disableRipple
                        label="Travel Summary"
                        {...a11yProps(
                          trip_data[0].reviewer === true ? 0 + 1 : 0
                        )}
                      />

                      <Tab
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                        disableRipple
                        label="Annextures and Personal Details"
                        {...a11yProps(
                          trip_data[0].reviewer === true ? 1 + 1 : 1
                        )}
                      />
                      <Tab
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                        disableRipple
                        label="Travel Details"
                        {...a11yProps(
                          trip_data[0].reviewer === true ? 2 + 1 : 2
                        )}
                      />
                      <Tab
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                        disableRipple
                        label="Departure and Return Details"
                        {...a11yProps(
                          trip_data[0].reviewer === true ? 3 + 1 : 3
                        )}
                      />
                      <Tab
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                        disableRipple
                        label="Costs and Size of Delegation"
                        {...a11yProps(
                          trip_data[0].reviewer === true ? 4 + 1 : 4
                        )}
                      />

                      <Tab
                        label="Travel History and Statistics"
                        {...a11yProps(
                          trip_data[0].reviewer === true ? 5 + 1 : 5
                        )}
                      />
                    </Tabs>
                  </Box>
                  {trip_data[0].reviewer === true && (
                    <CustomTabPanel value={value} index={0}>
                      <ApprovedForm
                        cs_first_name={cs_first_name}
                        cs_last_name={cs_last_name}
                        digitalsignature={digitalsignature}
                        trip={trip_data[0]}
                        depRet={depature_and_return_data[0]
                        }
                        delegates={delegate_data}
                      />
                    </CustomTabPanel>
                  )}
                  <CustomTabPanel
                    value={value}
                    index={trip_data[0].reviewer === true ? 0 + 1 : 0}
                  >
                    <ChairSummary
                      user={user}
                      token={token}
                      setOpen={setOpen}
                      id={id}
                      setChairperson_confirm={setChairperson_confirm}
                      chairperson_confirm={chairperson_confirm}
                      trip={trip_data[0]}
                      depRet={depature_and_return_data[0]}
                      delegate_data={delegate_data}
                    />
                  </CustomTabPanel>
                  <CustomTabPanel
                    value={value}
                    index={trip_data[0].reviewer === true ? 1 + 1 : 1}
                  >
                    <FormHeaderDetail
                      trip={trip_data[0]}
                      chairperson_confirm={chairperson_confirm}
                    />
                    <PersonalDetails
                      trip={trip_data[0]}
                      actingOfficer={acting_data[0]}
                    />
                  </CustomTabPanel>
                  <CustomTabPanel
                    value={value}
                    index={trip_data[0].reviewer === true ? 2 + 1 : 2}
                  >
                    <TravelDetails trip={trip_data[0]} />
                  </CustomTabPanel>
                  <CustomTabPanel
                    value={value}
                    index={trip_data[0].reviewer === true ? 3 + 1 : 3}
                  >
                    <DepartureReturnDetails
                      trip={trip_data[0]}
                      depRet={depature_and_return_data[0]}
                      token={token}
                    />
                  </CustomTabPanel>
                  <CustomTabPanel
                    value={value}
                    index={trip_data[0].reviewer === true ? 4 + 1 : 4}
                  >
                    <DelegationSizeDetails
                      trip={trip_data[0]}
                      delegates={delegate_data}
                      depRet={depature_and_return_data[0]}
                    />
                  </CustomTabPanel>
                  {/* <CustomTabPanel value={value} index={4}>
                    <ConfirmationDetails
                      trip={trip_data[0]}
                      digitalsignature={digitalsignature}
                    />
                  </CustomTabPanel> */}
                  <CustomTabPanel
                    value={value}
                    index={trip_data[0].reviewer === true ? 5 + 1 : 5}
                  >
                    <HistandStats
                      token={token}
                      user={user}
                      trip={trip_data[0]}
                      digitalsignature={digitalsignature}
                    />
                  </CustomTabPanel>
                </div>

                {/* <ConcurrenceDetails trip={trip} />

            <Divider sx={{ py: 2, my: 2 }} />

            <ApprovalDetails trip={trip} /> */}
              </div>
            )}
          </div>
        )}

        <Divider sx={{ my: 10 }} />
      </Paper>
    </div>
  );
}
