import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { memo } from "react";

function ApprovedTripsWidget(props) {
  const { reviewer_stats } = props;

  return (
    <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden">
      <div className="flex items-center justify-between px-8 pt-12">
        <Typography
          variant="p"
          className="px-12 text-xs font-medium tracking-tight leading-6 truncate"
          color="text.secondary"
        >
          Approved Applications
        </Typography>
      </div>
      <div className="text-center mt-8 pb-24">
        <Typography
          variant="p"
          className="text-5xl sm:text-6xl font-bold tracking-tight leading-none text-green-500"
        >
          {reviewer_stats}
        </Typography>
        <br />
        <Typography
          variant="p"
          className="text-md font-medium text-green-600 dark:text-green-500"
        >
          {reviewer_stats > 1 ? "Trips" : "Trip"}
        </Typography>
      </div>

      {/* <Typography
        variant="p"
        className="flex text-xs items-baseline justify-center w-full pt-12 pb-24"
        color="text.secondary"
      >
        <span className="truncate">Travelled This Year</span>
      </Typography> */}
    </Paper>
  );
}

export default memo(ApprovedTripsWidget);
