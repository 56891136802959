import * as React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import TravelFormConfig from "./forms/TravelFormConfig";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./components/Theme";
import SignUp from "./auth/SignUp";
import DrawerLeft, { Main } from "./components/drawer/Drawer";
import { CssBaseline } from "@mui/material";
import UserDashboard from "./dashboards/users/UserDashboard";
import UserForm from "./dashboards/users/UserForm";
import SignOut from "./auth/SignOut";
import ChairpersonDash from "./dashboards/chairperson/Chairperson";
import ChairpersonConfirmation from "./dashboards/chairperson/ChairpersonConfirmation";
import CSDash from "./dashboards/CS/CS";
import CSConfirmation from "./dashboards/CS/CSConfirmation";
import HOPSDash from "./dashboards/HOPS/HOPS";
import HOPSConfirmation from "./dashboards/HOPS/HOPSConfirmation";
import MFADash from "./dashboards/MFA/MFA";
import MFAConfirmation from "./dashboards/MFA/MFAConfirmation";
import FAQHome from "./faqs/FAQHome";
import ForgotPassword from "./auth/ForgotPassword";
import Confirmation from "./auth/Confirmation";
import ResetPassword from "./auth/ResetPassword";
import ChangePassword from "./auth/ChangePassword";
import EditForm from "./forms/edit/Edit";
import { Terms } from "./faqs/Terms";
import ConfirmRedirect from "./auth/ConfirmRedirect";
import PrivateVisit from "./forms/PrivateVisit";
import CheckingDash from "./dashboards/checking/Checking";
import CheckingConfirmation from "./dashboards/checking/CheckingConfirmation";
import { backend_url } from "./components/Constants";
import VerifyQr from "./components/VerifyQR";
import { UpdateUserProfile } from "./auth/UpdateUserProfile";
import Register from "./auth/Register";
import { CheckingEdit } from "./dashboards/checking/CheckingEdit";
import { SendOTP } from "./auth/SendOTP";
import VerifyOTP from "./auth/VerifyOTP";
import SignInPassword from "./auth/SignInPassword";
import PasswordConfirmation from "./auth/PasswordConfirmation";
import HEDash from "./dashboards/HE/HE";
import HEConfirmation from "./dashboards/HE/HEConfirmation";
import PSConfirmation from "./dashboards/PS/PSConfirmation";
import PSDash from "./dashboards/PS/PS";
import NotFound from "./components/NotFound";
import AfterTripsList from "./components/afterTrips/AfterTripsList";
import Reports from "./components/reports/Reports";

function App(props) {
  const { window } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });
  const token = localStorage.getItem("access_token");
  const [open, setOpen] = React.useState(isMobile === true ? false : true);
  const [user, setUser] = React.useState("");

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    fetch(`${backend_url}/auth/user/me/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUser(data.user);
        data.code === "token_not_valid" && localStorage.clear();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  return (
    <BrowserRouter>
      {user !== undefined && token ? (
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <DrawerLeft
            user={user}
            token={token}
            window={window}
            open={open}
            handleDrawerToggle={handleDrawerToggle}
          >
            <Main open={open}>
              <Routes>
                {/* Dashboard for CAS & PS & CEO */}
                {user &&
                  (user.designation === "CEO" ||
                    user.designation === "CAS") && (
                    <>
                      <Route
                        path="/"
                        element={<UserDashboard token={token} user={user} />}
                      >
                        <Route
                          path=":id"
                          element={
                            <UserForm
                              token={token}
                              user={user}
                              setOpen={setOpen}
                            />
                          }
                        />
                      </Route>

                      <Route path="verify/:id" element={<VerifyQr />} />

                      <Route
                        path="form"
                        element={
                          <TravelFormConfig
                            setOpen={setOpen}
                            token={token}
                            user={user}
                          />
                        }
                      />
                      <Route
                        path="private-trip"
                        element={
                          <PrivateVisit
                            setOpen={setOpen}
                            token={token}
                            user={user}
                          />
                        }
                      />
                      <Route
                        path="edit-form/:id"
                        element={
                          <EditForm
                            setOpen={setOpen}
                            token={token}
                            user={user}
                          />
                        }
                      />
                    </>
                  )}

                {/* {user && user.designation === "PS" && (
                  <>
                    <Route
                      path="/"
                      element={<PSDashboard token={token} user={user} />}
                    >
                      <Route
                        path=":id"
                        element={
                          <PSForm token={token} user={user} setOpen={setOpen} />
                        }
                      />
                    </Route>

                    <Route path="verify/:id" element={<VerifyQr />} />

                    <Route
                      path="form"
                      element={
                        <TravelFormConfig
                          setOpen={setOpen}
                          token={token}
                          user={user}
                        />
                      }
                    />
                    <Route
                      path="private-trip"
                      element={
                        <PrivateVisit
                          setOpen={setOpen}
                          token={token}
                          user={user}
                        />
                      }
                    />
                    <Route
                      path="edit-form/:id"
                      element={
                        <EditForm setOpen={setOpen} token={token} user={user} />
                      }
                    />
                  </>
                )} */}

                {/* Dashboard for Chair */}
                {user && user.designation === "CHAIRPERSON" && (
                  <>
                    <Route
                      path="/"
                      element={<ChairpersonDash token={token} user={user} />}
                    >
                      <Route
                        path=":id"
                        element={
                          <ChairpersonConfirmation
                            setOpen={setOpen}
                            token={token}
                            user={user}
                          />
                        }
                      />
                    </Route>

                    <Route path="verify/:id" element={<VerifyQr />} />

                    <Route
                      path="form"
                      element={
                        <TravelFormConfig
                          setOpen={setOpen}
                          token={token}
                          user={user}
                        />
                      }
                    />
                    <Route
                      path="private-trip"
                      element={
                        <PrivateVisit
                          setOpen={setOpen}
                          token={token}
                          user={user}
                        />
                      }
                    />
                    <Route
                      path="edit-form/:id"
                      element={
                        <EditForm setOpen={setOpen} token={token} user={user} />
                      }
                    />
                  </>
                )}

                {/* Dashboard for CS */}
                {user && user.designation === "CS" && (
                  <>
                    <Route
                      path="/"
                      element={<CSDash token={token} user={user} />}
                    >
                      <Route
                        path=":id"
                        element={
                          <CSConfirmation
                            setOpen={setOpen}
                            token={token}
                            user={user}
                          />
                        }
                      />
                    </Route>

                    <Route path="verify/:id" element={<VerifyQr />} />

                    <Route
                      path="form"
                      element={
                        <TravelFormConfig
                          setOpen={setOpen}
                          token={token}
                          user={user}
                        />
                      }
                    />
                    <Route
                      path="private-trip"
                      element={
                        <PrivateVisit
                          setOpen={setOpen}
                          token={token}
                          user={user}
                        />
                      }
                    />
                    <Route
                      path="edit-form/:id"
                      element={
                        <EditForm setOpen={setOpen} token={token} user={user} />
                      }
                    />
                  </>
                )}

                {user && user.designation === "PS" && (
                  <>
                    <Route
                      path="/"
                      element={<PSDash token={token} user={user} />}
                    >
                      <Route
                        path=":id"
                        element={
                          <PSConfirmation
                            setOpen={setOpen}
                            token={token}
                            user={user}
                          />
                        }
                      />
                    </Route>

                    <Route path="verify/:id" element={<VerifyQr />} />

                    <Route
                      path="form"
                      element={
                        <TravelFormConfig
                          setOpen={setOpen}
                          token={token}
                          user={user}
                        />
                      }
                    />
                    <Route
                      path="private-trip"
                      element={
                        <PrivateVisit
                          setOpen={setOpen}
                          token={token}
                          user={user}
                        />
                      }
                    />
                    <Route
                      path="edit-form/:id"
                      element={
                        <EditForm setOpen={setOpen} token={token} user={user} />
                      }
                    />
                  </>
                )}

                {/* Dashboard for HOPS Checking */}
                {user && user.designation === "HOPS_CHECKING" && (
                  <>
                    <Route
                      path="/"
                      element={<CheckingDash token={token} user={user} />}
                    >
                      <Route
                        path=":id"
                        element={
                          <CheckingConfirmation
                            setOpen={setOpen}
                            token={token}
                            user={user}
                          />
                        }
                      />
                    </Route>

                    <Route
                      path="checking/edit/:id"
                      element={
                        <CheckingEdit
                          setOpen={setOpen}
                          token={token}
                          user={user}
                        />
                      }
                    />
                    <Route
                      path="after-trip-uploads"
                      element={
                        <AfterTripsList
                          setOpen={setOpen}
                          token={token}
                          user={user}
                        />
                      }
                    />
                    <Route
                      path="reports"
                      element={
                        <Reports setOpen={setOpen} token={token} user={user} />
                      }
                    />
                    <Route path="verify/:id" element={<VerifyQr />} />
                  </>
                )}

                {/* Dashboard for HOPS and HOPS_PA */}
                {user &&
                  (user.designation === "HOPS_APPROVAL" ||
                    user.designation === "HOPS_PA") && (
                    <>
                      <Route
                        path="/"
                        element={<HOPSDash token={token} user={user} />}
                      >
                        <Route
                          path=":id"
                          element={
                            <HOPSConfirmation
                              setOpen={setOpen}
                              token={token}
                              user={user}
                            />
                          }
                        />
                      </Route>

                      <Route path="verify/:id" element={<VerifyQr />} />

                      <Route
                        path="form"
                        element={
                          <TravelFormConfig
                            setOpen={setOpen}
                            token={token}
                            user={user}
                          />
                        }
                      />
                      <Route
                        path="private-trip"
                        element={
                          <PrivateVisit
                            setOpen={setOpen}
                            token={token}
                            user={user}
                          />
                        }
                      />
                    </>
                  )}

                {/* Dashboard for HE */}
                {user && user.designation === "HE" && (
                  <>
                    <Route
                      path="/"
                      element={<HEDash token={token} user={user} />}
                    >
                      <Route
                        path=":id"
                        element={
                          <HEConfirmation
                            setOpen={setOpen}
                            token={token}
                            user={user}
                          />
                        }
                      />
                    </Route>

                    <Route path="verify/:id" element={<VerifyQr />} />

                    <Route
                      path="form"
                      element={
                        <TravelFormConfig
                          setOpen={setOpen}
                          token={token}
                          user={user}
                        />
                      }
                    />
                    <Route
                      path="private-trip"
                      element={
                        <PrivateVisit
                          setOpen={setOpen}
                          token={token}
                          user={user}
                        />
                      }
                    />
                  </>
                )}

                {/* Ministry of foreign affairs */}
                {user && user.designation === "MIN_FOREIGN" && (
                  <>
                    <Route
                      path="/"
                      element={<MFADash token={token} user={user} />}
                    >
                      <Route
                        path=":id"
                        element={
                          <MFAConfirmation
                            token={token}
                            user={user}
                            setOpen={setOpen}
                          />
                        }
                      />
                    </Route>

                    <Route path="verify/:id" element={<VerifyQr />} />
                  </>
                )}

                <Route path="faq" element={<FAQHome />} />
                <Route
                  path="update-profile"
                  element={<UpdateUserProfile token={token} user={user} />}
                />
                <Route path="terms-and-conditions" element={<Terms />} />
                <Route path="sign-out" element={<SignOut token={token} />} />
                <Route path="change-password" element={<ChangePassword />} />
              </Routes>
            </Main>
          </DrawerLeft>
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/sign-in" element={<SendOTP />} />
            <Route path="/sign-in/password" element={<SignInPassword />} />
            {/* <Route path="/sign-in1" element={<SignIn />} /> */}
            {/* <Route path="/send-otp" element={<SendOTP />} /> */}
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/verify-otp" element={<VerifyOTP />} />
            <Route path="/register" element={<Register />} />
            <Route path="/terms-and-conditions" element={<Terms />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/auth/user/password_reset/"
              element={<ResetPassword />}
            />
            <Route path="/confirmation" element={<Confirmation />} />
            <Route
              path="/password-confirmation"
              element={<PasswordConfirmation />}
            />
            <Route
              path="/auth/user/verify_email"
              element={<ConfirmRedirect />}
            />
            <Route path="verify/:id" element={<VerifyQr />} />
            {/* Redirect when token expires */}
            <Route path="/:id" element={<Navigate to="/sign-in" />} />
            <Route path=":id" element={<Navigate to="/sign-in" />} />
            <Route path="/" element={<Navigate to="/sign-in" />} />
            <Route path="/hops" element={<Navigate to="/sign-in" />} />
            <Route path="/private-trip" element={<Navigate to="/sign-in" />} />
            <Route
              path="/checking/edit/:id"
              element={<Navigate to="/sign-in" />}
            />
            <Route path="/ceo-cs-ps" element={<Navigate to="/sign-in" />} />
            <Route path="/form" element={<Navigate to="/sign-in" />} />
            <Route path="/form-detail" element={<Navigate to="/sign-in" />} />
            <Route path="*" element={<NotFound />} /> {/* Add the 404 route */}
          </Routes>
        </ThemeProvider>
      )}
    </BrowserRouter>
  );
}

export default App;
