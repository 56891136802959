import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { Avatar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import logo1 from "../../assets/coat.png";
import his_excellency from "../../assets/his_excellency.jpg";
import { backend_url } from "../Constants";
import { Link } from "react-router-dom";

const DrawerHead = styled("div")(({ theme }) => ({
  // display: 'flex',
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export const DrawerHeader = (props) => {
  const { user, handleDrawerToggle, open } = props;

  return (
    <div>
      <div className="grid grid-cols-12 gap-x-4 mx-4 mt-4 ">
        <div className="col-span-6 self-center tracking-tight">
          <Link
            to="/"
            underline="none"
            className="col-span-8 self-center tracking-tight"
          >
            <img src={logo1} className="font-bold h-32" alt="logo" />
          </Link>
        </div>
        <div className="col-span-2 " />

        <div className="col-span-4 self-right">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ mr: 2, ...(open === false && { display: "none" }) }}
            className="w-40 h-40 p-0 "
          >
            <MenuIcon />
          </IconButton>
        </div>
      </div>

      <DrawerHead className="relative flex flex-col items-center justify-center my-32 shadow-0">
        {user.designation === "HE" ? (
          <>
            <Avatar
              sx={{
                backgroundColor: "background.paper",
                color: "text.secondary",
              }}
              className="avatar text-32 font-bold w-96 h-96"
              src={his_excellency}
              alt={"Profile photo"}
            >
              Dr William Samoei Ruto
            </Avatar>

            <Typography className="text-14 whitespace-nowrap font-medium">
              {/* His Excellency */}
              Dr William Samoei Ruto
            </Typography>
            {/* <Typography
              className="email text-10 whitespace-nowrap"
              color="text.secondary"
            >
              Dr William Samoei Ruto
            </Typography> */}
          </>
        ) : (
          <>
            <Avatar
              sx={{
                backgroundColor: "background.paper",
                color: "text.secondary",
              }}
              className="avatar text-32 font-bold w-96 h-96"
              src={`${backend_url}${user.profile_photo}`}
              alt={"Profile photo"}
            >
              {user && `${user.first_name[0]}`}
            </Avatar>

            <Typography className="text-14 whitespace-nowrap font-medium">
              {user && `${user.first_name} ${user.last_name}`}
            </Typography>
            {/* <Typography
          className="designation text-10 whitespace-nowrap"
          color="text.secondary"
        >
          {user && user.designation && user.designation}
        </Typography> */}
            <Typography
              className="flex  email text-10"
              color="text.secondary"
              style={{
                // width: "240px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {user && user.designation && user.designation === "HOPS_APPROVAL"
                ? "HOPS"
                : user.designation === "HOPS_PA"
                ? "HOPS PA"
                : user.designation === "HOPS_CHECKING"
                ? "HOPS Reviewer"
                : user.designation}
            </Typography>
            <Typography
              className="flex  email text-10"
              color="text.secondary"
              style={{
                maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {user && user.email && user.email}
            </Typography>
          </>
        )}
      </DrawerHead>
    </div>
  );
};
