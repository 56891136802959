import { useState, useEffect } from "react";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import { backend_url } from "../Constants";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import format from "date-fns/format";
import Uploads from "./Uploads";

export default function WithoutUploadDocuments(props) {
  const { token } = props;
  const [withoutUpload, setWithoutUpload] = useState([]);
  const [open, setOpen] = useState(false);

  const trip_data =
    withoutUpload && withoutUpload.results ? withoutUpload.results : [];

  useEffect(() => {
    fetch(`${backend_url}/traveller/without_after_trip/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setWithoutUpload(data.results);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [token]);

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  return (
    <div className="py-4 px-32 w-full max-w-3xl">
      <Typography
        component="h5"
        variant="p"
        className="px-4 py-16 text-lg font-bold"
      >
        Select a trip to upload your documents from your last trip
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="sm:px-12 py-1 sm:py-3 sm:my-3">
          {withoutUpload && withoutUpload.length === 0 && (
            <div>
              <motion.div
                variants={item}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                className="flex flex-col flex-auto w-full max-h-full m-0 sm:m-8"
              >
                <List className="w-full m-0 p-0" disablePadding>
                  <ListItem className="px-12 py-6 my-6">
                    <ListItemText
                      primary={
                        <Typography
                          component="h5"
                          className="font-extrabold"
                          variant="h5"
                        >
                          No travel applications are available
                        </Typography>
                      }
                    />
                    <div className="flex items-center"></div>
                  </ListItem>
                </List>
              </motion.div>
            </div>
          )}
          <div>
            <motion.div variants={item}>
              {withoutUpload.map((trip_details) => {
                return (
                  trip_details.trip_id !== trip_data.trip_id && (
                    <List
                      key={trip_details.trip_id}
                      disablePadding
                      sx={{ bgcolor: "background.paper", px: 1, my: 2 }}
                    >
                      {open && (
                        <Uploads
                          trip_id={trip_details.trip_id}
                          token={token}
                          open={open}
                          setOpen={setOpen}
                        />
                      )}
                      <ListItemButton
                        className="px-0 sm:-mx-8"
                        disableGutters
                        onClick={() => setOpen(true)}
                        // component={NavLinkAdapter}
                        // to={`${trip_details.trip_id}`}
                      >
                        <IconButton sx={{ color: "secondary.dark" }}>
                          <ConnectingAirportsIcon fontSize="inherit" />
                        </IconButton>
                        <ListItemText
                          className="px-2"
                          primary={
                            <div>
                              <div className="sm:flex sm:flex-row items-center space-x-12">
                                <Typography
                                  component="h6"
                                  variant="h6"
                                  className="sm:flex sm:flex-auto sm:items-left sm:text-left"
                                >
                                  {trip_details.trip_type === "public"
                                    ? trip_details.program_title
                                    : `${trip_details.reason_for_tr} - Private Travel`}
                                </Typography>
                                <Typography
                                  className="flex py-6 sm:py-0 sm:flex-auto text-left sm:text-right text-8"
                                  variant="p"
                                >
                                  {trip_details.program_from !== undefined &&
                                  trip_details.trip_type === "public"
                                    ? format(
                                        new Date(trip_details.program_from),
                                        "dd-MMM-yyyy"
                                      )
                                    : format(
                                        new Date(trip_details.program_from),
                                        "dd-MMM-yyyy"
                                      )}
                                </Typography>
                              </div>
                              <Typography variant="p">
                                {trip_details.user.salutation !== undefined &&
                                  trip_details.user.salutation}
                                {". "}
                                {trip_details.user.first_name}{" "}
                                {trip_details.user.last_name} -{" "}
                                {trip_details.user.designation} -{" "}
                                {trip_details.user.designation === "CEO"
                                  ? trip_details.user.institutions
                                  : trip_details.user.designation ===
                                    "CHAIRPERSON"
                                  ? trip_details.user.institutions
                                  : trip_details.user.designation === "PS"
                                  ? trip_details.user.State_departments
                                  : trip_details.user.designation === "CS" &&
                                    trip_details.user.ministry}{" "}
                              </Typography>
                            </div>
                          }
                          secondary={
                            <Typography variant="p">
                              {trip_details.country}
                            </Typography>
                          }
                        />
                      </ListItemButton>

                      <div className="flex mb-4 px-24">
                        <Typography
                          variant="p"
                          className="text-8 font-medium"
                          color="secondary"
                        >
                          <span className="text-green-500">
                            Recently travelled - select to upload documents
                          </span>
                        </Typography>
                      </div>
                      <Divider />
                    </List>
                  )
                );
              })}
            </motion.div>
          </div>
        </div>
      </Box>
    </div>
  );
}
