import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { memo } from "react";

function TicketCostWidget(props) {
  const { cost_stats } = props;

  return (
    <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden">
      <div className="flex items-center justify-between px-8 pt-12">
        <Typography
          variant="p"
          className="px-12 text-xs font-medium tracking-tight leading-6 truncate"
          color="text.secondary"
        >
          Total Ticket Cost
        </Typography>
      </div>
      <div className="text-center mt-8 pb-24">
        <Typography
          variant="p"
          className="text-2xl font-bold tracking-tight leading-none text-red-500"
        >
          {cost_stats.total_ticket_cost.toLocaleString()}
        </Typography>
        <br />
        <Typography
          variant="p"
          className="text-md font-medium text-red-600 dark:text-red-500"
        >
          KES
          {/* {cost_stats.total_ticket_cost > 1 ? "Trips" : "Trip"} */}
        </Typography>
      </div>

      {/* <Typography
        variant="p"
        className="flex text-xs items-baseline justify-center w-full pt-12 pb-24"
        color="text.secondary"
      >
        <span className="truncate">Travelled This Year</span>
      </Typography> */}
    </Paper>
  );
}

export default memo(TicketCostWidget);
