import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { UpdateProfile } from "../../components/UpdateProfile";
import { useParams } from "react-router-dom";
import { Avatar } from "@mui/material";
import { backend_url } from "../../components/Constants";

function HOPSHeader(props) {
  const { token, user } = props;
  const [open, setOpen] = useState(
    user && user.is_updated === true ? false : true
  );
  const { id } = useParams();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    user && (
      <div className="flex flex-col w-full px-8 sm:px-32">
        <div className="flex flex-col md:flex-row flex-auto md:items-center min-w-0 mb-24 mt-48 sm:mt-64 sm:mb-16">
          <UpdateProfile
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            open={open}
            setOpen={setOpen}
            token={token}
            user={user}
          />

          <div className="flex flex-auto items-center min-w-0">
            <Avatar
              className="flex-0 w-64 h-64"
              alt="user photo"
              src={`${backend_url}${user.profile_photo}`}
            >
              {user.first_name[0]}
            </Avatar>
            <div className="flex flex-col min-w-0 ml-8">
              <Typography
                variant="p"
                className="text-2xl font-semibold tracking-tight leading-7 md:leading-snug "
              >
                {user.designation === "HOPS_APPROVAL" && `Welcome back, HOPS!`}
                {user.designation === "HOPS_PA" && `${user.first_name} ${user.last_name}`}
              </Typography>
            </div>
          </div>

          {!id && (
            <div className="flex items-center mt-12 sm:mt-24 sm:mx-8 space-x-12">
              {/* <div>
                <Button
                  id="basic-button"
                  aria-controls={openTrip ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openTrip ? "true" : undefined}
                  onClick={handleClick}
                  variant="contained"
                  color="secondary"
                  startIcon={<AddIcon size={20}>heroicons-solid:mail</AddIcon>}
                >
                  New Travel Application
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openTrip}
                  onClose={handleCloseTrip}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    component={NavLinkAdapter}
                    to="/form"
                    onClick={handleCloseTrip}
                  >
                    Official Travel Application
                  </MenuItem>
                  <MenuItem
                    component={NavLinkAdapter}
                    to="/private-trip"
                    onClick={handleCloseTrip}
                  >
                    Private Travel
                  </MenuItem>
                </Menu>
              </div> */}

              <Button
                className="whitespace-nowrap truncate"
                variant="contained"
                color="secondary"
                startIcon={
                  <AccountCircleIcon size={20}>
                    heroicons-solid:mail
                  </AccountCircleIcon>
                }
                onClick={handleClickOpen}
              >
                Update Profile
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  );
}

export default HOPSHeader;
